import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";

import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import Button from "components/CustomButtons/Button.jsx";
import Globales from "utils/Globales";


import Cargando from "../Generales/Cargando.jsx";

import style from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.jsx";





class SectionContentAreas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [1, 2, 3],
      productos: []
    };

    /**variables globales para totales */
    global.Total=0;
    global.Subtotal=0;
    global.IVA=0;
    global.Descuento=0;
    global.TotalProductos=0;
    this.handleToggle = this.handleToggle.bind(this);
  }
  componentDidMount() {
    this.ObtenerDetallesProducto()

  }
  handleToggle(idProducto) {
    global.Subtotal=0;
    this.state.productos.map(m => {
      if (m._id == idProducto) {
        m.Marca = !m.Marca;
        m.Cantidad = 0;
        m.PrecioTotal = 0;

      }
      global.Subtotal = global.Subtotal + m.PrecioTotal;
    })
    this.CalcularTotales();
    this.setState({ a: "a" })

  }
  AgregarPiezas(idProducto, Opcion) {
    this.state.productos.map(m => {
      if (m._id == idProducto) {
        if (Opcion == true) {
          m.Cantidad = m.Cantidad + 1;
        } else {
          if (m.Cantidad != 0) {
            m.Cantidad = m.Cantidad - 1;
          }
        }
        /**CALCULAR TOTALES */
        m.PrecioTotal = m.Cantidad * m.PrecioPublico;
     
      }
    })
    this.CalcularTotales();
    this.setState({ a: "a" })
  }
  CalcularTotales(){
    global.Subtotal=0;
    global.Total=0;
    global.IVA=0;
    global.Descuento=0;
    global.TotalProductos=0;
    this.state.productos.map(m=>{
        if(m.Marca == true){
          global.TotalProductos= global.TotalProductos + m.Cantidad;
    global.Subtotal = global.Subtotal + m.PrecioTotal;
    global.IVA = ((global.Subtotal - global.Descuento) * (16/100));
    global.Total = global.Subtotal + global.Descuento + global.IVA;
        }
    })
    
    this.props.eventoTabla(this.state.productos)
  }


  ObtenerDetallesProducto() {
    fetch(Globales.Url + 'Productos/Productos/ObtenerAbanicoDeProductosPorModelo', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({ idModelo: this.props.idModelo })
    })
      .then(res => res.json())
      .then(data => {
      //console.log("TCL: data", data)
        if (data.error) { //Error al obtener los registros

        } else { //Obtuvo los registros correctamente
          data.data.map(m => {
            m["Marca"] = false;
            m["Cantidad"] = 0;
            m["PrecioTotal"] = 0;
          })
          this.setState({
            productos: data.data
          })
        }
      });
  }
  
  render() {
    const { classes, ...rest } = this.props;


    return (
      <div {...rest} className="cd-section" id="contentAreas">
        <div className="scrollTablaConfiguracionProducto">

          {
            this.state.productos.length>0 ? 
            <div id="tables">

            <GridContainer>

              <GridItem
                xs={12}
                sm={10}
                md={12}
                className={`${classes.mrAuto} ${classes.mlAuto}`}
              >

                <Table
                  striped
                  tableHead={[
                    "",
                    "Nombre",
                    "Atributos",
                    "Precio",
                    "Piezas",
                    "Total"
                  ]}
                  tableData={this.state.productos.map((producto, index) =>
                    [
                      <Checkbox
                        checked={producto.Marca}
                        onClick={() => this.handleToggle(producto._id)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />,

                      producto.NombreProducto,
                      producto.atributosAgregados.map(atributos =>
                        <div>
                          <table>
                            <tbody>
                              <tr>
                                <td>{atributos.nombre}</td>
                                <td><div style={{  width: '30px', height: "10px", background: atributos.codigoColor }}></div></td>
                              </tr>
                            </tbody>
                          </table>
                          {/* <div style={{ float: 'left' }} >
                            &nbsp;&nbsp;
                    </div>
                          <div style={{ float: 'left', width: '30px', height: "10px", background: atributos.codigoColor }}></div>
                          <br /> */}

                        </div>
                      ),
                      '$' + parseFloat(producto.PrecioPublico, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString(),
                      <div className={classes.buttonGroup} >
                        <Button onClick={() => this.AgregarPiezas(producto._id, false)} disabled={!producto.Marca} color="github" size="sm">-</Button>

                        <Button color="danger" size="sm" disabled="true">
                          <span style={{ fontSize: '11px' }}> {producto.Cantidad}</span>
                        </Button>

                        <Button onClick={() => this.AgregarPiezas(producto._id, true)} disabled={!producto.Marca} color="github" size="sm">+</Button>
                      </div>,
                      '$' + parseFloat(producto.PrecioTotal, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString(),
                    ]
                  )
                  }
                  customCellClasses={[
                    classes.textCenter,
                    classes.padding0,
                    classes.textRight,
                    classes.textRight
                  ]}
                  customClassesForCells={[0, 1, 5, 6]}
                  customHeadCellClasses={[
                    classes.textCenter,
                    classes.textRight,
                    classes.textRight
                  ]}
                  customHeadClassesForCells={[0, 5, 6]}
                />
              </GridItem>
              <GridItem >
              <GridContainer className={`${classes.textCenter}`}>
              <GridItem sm={3} md={3}>
              <span style={{fontSize:'1.3em'}} > Total de productos: {global.TotalProductos} </span><br/> 
                </GridItem>
                <GridItem sm={3} md={3}   >
                <span style={{fontSize:'1.3em'}} >Subtotal: {'$' + parseFloat(global.Subtotal, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()}</span> 
                </GridItem>
                <GridItem sm={3} md={3}>
                <span style={{fontSize:'1.3em'}}>IVA: { '$' + parseFloat(global.IVA, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()}</span>  
                </GridItem>
                <GridItem sm={3} md={3}>
                <span style={{fontSize:'1.3em'}} >Descuento: { '$' + parseFloat(global.Descuento, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()}</span> <br/>
                </GridItem>
              
             
              </GridContainer>

              <GridContainer className={`${classes.textCenter}`}>
                <GridItem  md={12} sm={12}>
           
                <span style={{fontSize:'1.3em'}} >Total: {'$' + parseFloat(global.Total , 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()}</span> 

                </GridItem>
              </GridContainer>
              <GridContainer className={`${classes.textCenter}`}>
                <GridItem  md={12} sm={12}>
           
                <span className="TextDanger" style={{fontSize:'1.3em'}}>Más costo de envío</span>

                </GridItem>
              </GridContainer>

              
              </GridItem>

            </GridContainer>

          </div>
            
            :     <Cargando/>

          }

      

       
        </div>

      </div>
    );
  }
}

export default withStyles(style)(SectionContentAreas);
