/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
import UserIcon from "@material-ui/icons/Person";
// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import ViewDay from "@material-ui/icons/ViewDay";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import AccountBalance from "@material-ui/icons/AccountBalance";
import ArtTrack from "@material-ui/icons/ArtTrack";
import ViewQuilt from "@material-ui/icons/ViewQuilt";
import LocationOn from "@material-ui/icons/LocationOn";
import Fingerprint from "@material-ui/icons/Fingerprint";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Apps from "@material-ui/icons/AttachMoney";
import Store from "@material-ui/icons/Store";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PersonAdd from "@material-ui/icons/PersonAdd";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import Error from "@material-ui/icons/Error";
import Cookies from 'js-cookie'
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import ModalAccion from '../../components/Generales/ModalAccion';
import Globales from "utils/Globales";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";

import { Input } from "@material-ui/core";

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // liveDemo: false
    };
    this.eventoModal = this.eventoModal.bind(this);
  }

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  openModal = () => {
    this.setState({ liveDemo: true });
  }

  eventoModal(e) {
    if (!e) {
      this.setState({ liveDemo: false });
      return;
    }
    this.cerrarSesion(this.seleccionado);

  }

  cerrarSesion() {
    this.setState({ liveDemo: false });
    Cookies.remove('id', { path: '' });
    Cookies.remove('Usuario', { path: '' });
    Cookies.remove('Nombre', { path: '' });
    Cookies.remove('Correo', { path: '' });
    Cookies.remove('Telefono', { path: '' });
    Cookies.remove('TipoUsuario', { path: '' });
    Cookies.remove('NombreUsuario', { path: '' });    
    Cookies.remove('token', { path: '' });
    Globales.token = '';
    window.location.href = '/signup-page';
  }

  render() {
    const { classes, dropdownHoverColor } = this.props;
    const popover = {
      position: 'absolute',
      right: '0',
    }
    return (
      <div style={popover}>
        <List className={classes.list + " " + classes.mlAuto}>
          <ListItem className={classes.listItem}>
            <CustomDropdown
              noLiPadding
              navDropdown
              hoverColor={dropdownHoverColor}
              buttonText="Examples"
              buttonProps={{
                className: classes.navLink,
                color: "transparent"
              }}
              buttonIcon={ViewCarousel}
              dropdownList={[
                <Link to="/about-us" className={classes.dropdownLink}>
                  <AccountBalance className={classes.dropdownIcons} /> About Us
            </Link>,
                <Link to="/blog-post" className={classes.dropdownLink}>
                  <ArtTrack className={classes.dropdownIcons} /> Blog Post
            </Link>,
                <Link to="/blog-posts" className={classes.dropdownLink}>
                  <ViewQuilt className={classes.dropdownIcons} /> Blog Posts
            </Link>,
                <Link to="/contact-us" className={classes.dropdownLink}>
                  <LocationOn className={classes.dropdownIcons} /> Contact Us
            </Link>,
                <Link to="/landing-page" className={classes.dropdownLink}>
                  <ViewDay className={classes.dropdownIcons} /> Landing Page
            </Link>,
                <Link to="/login-page" className={classes.dropdownLink}>
                  <Fingerprint className={classes.dropdownIcons} /> Login Page
            </Link>,
                <Link to="/pricing" className={classes.dropdownLink}>
                  <AttachMoney className={classes.dropdownIcons} /> Pricing Page
            </Link>,
                <Link to="/shopping-cart-page" className={classes.dropdownLink}>
                  <ShoppingBasket className={classes.dropdownIcons} /> Shopping Cart
            </Link>,
                <Link to="/ecommerce-page" className={classes.dropdownLink}>
                  <Store className={classes.dropdownIcons} /> Ecommerce Page
            </Link>,
                <Link to="/product-page" className={classes.dropdownLink}>
                  <ShoppingCart className={classes.dropdownIcons} /> Product Page
            </Link>,
                <Link to="/profile-page" className={classes.dropdownLink}>
                  <AccountCircle className={classes.dropdownIcons} /> Profile Page
            </Link>,
                <Link to="/signup-page" className={classes.dropdownLink}>
                  <PersonAdd className={classes.dropdownIcons} /> Signup Page
            </Link>,
                <Link to="/error-page" className={classes.dropdownLink}>
                  <Error className={classes.dropdownIcons} /> Error Page
            </Link>
              ]}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button href="/ecommerce-page" className={classes.navLink} color="transparent"><Apps />Galeria de Productos x2</Button>
          </ListItem>
          {/* Inicio de sesión o sesión de usuario*/}
          {Object.keys(Cookies.get()).length !== 0 ?
            <ListItem className={classes.listItem}>
              <CustomDropdown
                noLiPadding
                navDropdown
                hoverColor={dropdownHoverColor}
                buttonText={Cookies.get('Usuario')}
                buttonProps={{
                  className: classes.navLink,
                  color: "transparent"
                }}
                buttonIcon={UserIcon}
                dropdownList={[
                  <Link
                    to="/profile-page"
                    className={classes.dropdownLink}
                  >
                    Perfil
              </Link>,
                  <Link
                    to="/lista-deseos"
                    className={classes.dropdownLink}
                  >
                    Lista de deseos
                          </Link>,
                  <Link
                    to="#"
                    className={classes.dropdownLink}
                    onClick={() => this.openModal()}
                  >
                    Cerrar Sesión
              </Link>
                ]}
              />
            </ListItem>
            :
            <ListItem className={classes.listItem}>
              <Button href="/signup-page" to="/signup-page" className={classes.navLink} color="transparent"><UserIcon />Iniciar sesión</Button>
            </ListItem>
          }
          <ListItem className={classes.listItem}>
            <Button
              href="/shopping-cart-page"
              color={window.innerWidth < 960 ? "info" : "white"}
              className={classes.navButton}
              round
              size='sm'
            >
              <ShoppingCart className={classes.icons} /> 0
        </Button>
          </ListItem>
        </List>
        <div id="">
          <ModalAccion
            Titulo='Cerrar Sesión'
            body={'¿Seguro que deseas cerrar sesión ' + Cookies.get('Usuario') + '?'}
            liveDemo={this.state.liveDemo}
            eventClick={this.eventoModal}
          />
        </div>
      </div>
    );
  }
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};

export default withStyles(headerLinksStyle)(HeaderLinks);
