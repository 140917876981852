import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Globales from "utils/Globales";
import Pagination from "@material-ui/lab/Pagination";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.jsx";
import Categorias from '../../Generales/Controles/CategoriasTreeView.jsx'
import ModalTalla from '../ModalTalla.jsx'
import Accordion from "components/Accordion/Accordion.jsx";
import ZoomIn from "@material-ui/icons/ZoomIn";
import ModalImagen from '../ModalImagenProducto.jsx';
import Loader from 'react-loader-spinner';
import { Grid } from '@material-ui/core';
import '../../../Styles/GridStyle.css';

class SectionProducts extends React.Component {
  constructor(props) {
    super(props);
    this.obtenerProductosPorCategoria = this.obtenerProductosPorCategoria.bind(this)
    this.state = {
      ImagenObj: "",
      VerModalTallas: false,
      productos: [],
      imagen: '',
      mostrarProductos: [],
      VisorCategorias: [],
      ProductosTallas: [],
      idModelo: '',
      ProductoSeleccionado: null,
      CaracteristicasPorTalla: [],
      Tallas: [
        { id: "1", Talla: "CH" },
        { id: "2", Talla: "M" },
        { id: "3", Talla: "G" },
      ],
      modalImagen: false,
      currentPage: 1,
      itemsPerPage: 12,
      totalProductos: 0,
      isFiltrado: false,
      idCategoriaSeleccionada: '',
      Cargando: true,
    };
    global.imagen = '';
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {

    document.body.style.backgroundColor = '#FFFFFF';
    //   window.open("http://localhost:3000/login" , "Login" , "width=800,height=600,top=200,left=500,scrollbars=NO")
    this.obtenerProductos(this.state.currentPage);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      this.obtenerProductos(this.state.currentPage);
    }
  }

  handlePageChange(event, newPage) {
    //console.log(this.state.isFiltrado)
    this.setState({ currentPage: newPage }, () => {
      if (this.state.isFiltrado) {
        this.obtenerProductosPorCategoria(this.state.idCategoriaSeleccionada)
      } else {
        this.obtenerProductos(newPage);
      }
    });
  }
  ObtenerTallas(Producto, idCategoria, idAux, Productos) {
    //console.log("TCL: SectionProducts -> ObtenerTallas -> Productos", Productos)
    fetch(Globales.Url + 'Tallas/ObtenerTallasPorDiseno', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({ idCategoria: idCategoria })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log("TCL: SectionProducts -> ObtenerTallas -> data", data)
        if (data.error) {

        } else {
          var tallas = [];
          var productosTallas = [];
          data.data[0].Tallas.map(t => {
            tallas.push(t[0])
          })
          this.state.mostrarProductos.map(m => {
            if (idAux == m._id) {
              m.Producto.map(p => {
                productosTallas.push({ idProducto: p._id, Talla: p.Tamano, idDiseno: p._id })
              })
            }

          })
          this.setState({
            Tallas: tallas,
            ProductosTallas: productosTallas
          }, () => {
            this.SeleccionarProducto(Producto, Productos);

          })
        }
      })
  }

  /*   obtenerProductos() {
      fetch(Globales.Url + 'Productos/Productos/ListadoProductosWeb', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ Fin: global.Fin })
      }
      ).then(res => {
        global.response = res.status;
        res.json()
          .then(data => {
  
            if (data.error) { //Error al obtener los registros
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
              this.setState({ productosMostrar: [] });
              this.showNotification();
            } else { //Obtuvo los registros correctamente          
              this.setState({ mostrarProductos: data.mensaje });
            }
          })
          .catch(err => console.error(err));
      });
    } */

  obtenerProductos(pagina) {
    const porPagina = this.state.itemsPerPage;
    this.setState({
      Cargando: true
    })
    fetch(Globales.Url + 'Productos/Productos/ListadoProductosWebPaginado', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ pagina, porPagina })
    }
    ).then(res => {
      global.response = res.status;
      res.json()
        .then(data => {

          if (data.error) { //Error al obtener los registros
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
            this.setState({ productosMostrar: [] });
            this.showNotification();
          } else { //Obtuvo los registros correctamente          
            this.setState({ mostrarProductos: data.mensaje, totalProductos: data.totalRegistros });
          }
          this.setState({
            Cargando: false
          })
        })
        .catch(err => console.error(err));
    });
  }

  obtenerProductosPorCategoria(id) {
    this.setState({
      isFiltrado: true,
      idCategoriaSeleccionada: id,
      Cargando: true
    })

    let pagina = this.state.currentPage;
    const porPagina = this.state.itemsPerPage;
    fetch(Globales.Url + 'FiltrarProductosPorCategoria', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idCategoria: id, pagina, porPagina })
    }
    ).then(res => {
      global.response = res.status;
      res.json()
        .then(data => {
          if (data.error) { //Error al obtener los registros

          } else { //Obtuvo los registros correctamente
            //console.log("------------>", data.mensaje)
            this.setState({ mostrarProductos: data.mensaje, totalProductos: data.totalRegistros });

          }
          this.setState({
            Cargando: false
          })
        })
        .catch(err => console.error(err));
    });

  }

  SeleccionarProducto(Producto, Productos) {

    var caracteristicasPorTalla = [];
    Productos.Producto.map(m => {
      caracteristicasPorTalla.push({ idTalla: m.Tamano, caracteristicasAgregadas: m.caracteristicasAgregadas, atributosAgregados: m.atributosAgregados })

    })
    this.setState({
      VerModalTallas: true,
      ProductoSeleccionado: Producto,
      CaracteristicasPorTalla: caracteristicasPorTalla

    })

  }

  render() {
    const { classes } = this.props;
    const { currentPage, itemsPerPage, totalProductos } = this.state;
    let accordion;
    const { setOverflowXStyle } = this.props;

    if (this.props.id === "SectionProducts") {
      accordion = <GridContainer>
        <GridItem md={5} sm={5}>
          <p style={{ fontSize: `3em`, textAlign: `justify`, lineHeight: `1.5`, color: `white` }}>Selecciona tu prenda favorita y comienza a personalizar</p>
        </GridItem>
        <GridItem md={7} sm={7}>
          <p style={{ fontSize: `1.5em`, textAlign: `right`, lineHeight: `1.5`, color: `#FFD800`, fontWeight: `bold` }}>Utiliza nuestra herramienta en línea para diseñar tu prenda</p>
          <p style={{ fontSize: `1.5em`, textAlign: `right`, lineHeight: `1.5`, color: `white` }}>
            Con la mejor colección de arte, stickers y fuentes de la web, nuestra herramieta de diseño facilita la creación de prendas personalizadas que te encantarán
          </p>
        </GridItem>
        <GridItem md={12} sm={12} style={{ margin: "auto" }}>
          <br />
          <br />
          <GridContainer >
            {this.state.mostrarProductos.map(producto => {
              return (
                <GridItem md={3} sm={4} xs={6}>
                  <center>

                    <Card plain product>
                      <CardHeader noShadow image>
                        <div className="item">
                          <a style={{ cursor: 'pointer' }} onClick={() => this.setState({ modalImagen: true, ProductoSeleccionado: producto.Producto[0] })}>
                            <img width="auto" height="auto" src={producto.Producto[0].ImagenProducto != null ? "data:image/png;base64," + producto.Producto[0].ImagenProducto : null} alt=".." />
                            <div class="bg-hover">
                              <div class="info">
                                <ZoomIn style={{ color: 'white' }} />
                              </div>
                            </div>
                          </a>
                        </div>
                      </CardHeader>
                      <CardBody plain>
                        <a onClick={() => this.ObtenerTallas(producto.Producto[0], producto.Producto[0].idCategoria, producto._id, producto)}>
                          <div style={{ display: 'inline-flex' }}>
                            <h4 className={classes.cardTitle} style={{ color: `#FFD800`, cursor: 'pointer' }}
                            >
                              {producto.Producto[0].NombreProducto}&nbsp;
                              <svg xmlns="http://www.w3.org/2000/svg" height='15' viewBox="0 0 512 512">
                                <path fill="white" d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2
                             220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 
                             339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 
                             17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                              </svg>
                            </h4>
                            {/* <h4 className="btn"> */}

                            {/* </h4> */}
                          </div>
                        </a>
                        <center>
                          <p className={classes.description}>
                            {producto.Producto[0].Descripcion}
                          </p>
                        </center>
                        <center>
                          <p className={classes.description}>
                            {(new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(producto.Producto[0].PrecioPublico))}
                          </p>
                        </center>
                      </CardBody>
                    </Card>
                  </center>

                </GridItem>
              )
            })}
          </GridContainer>

        </GridItem>
      </GridContainer>
    } else {
      accordion = <GridContainer>
        <GridItem md={3} sm={3}>
          <Accordion
            active={""}
            activeColor="primary"
            collapses={[
              {
                title: "Categorías",
                content:
                  <Categorias RegresarIdCategoria={this.obtenerProductosPorCategoria} />
              },
            ]}
          />
        </GridItem>
        <GridItem md={9} sm={9}>
          <br />
          <br />
          {this.state.Cargando === false ? (
            <div>
              <Grid container spacing={2}>
                {this.state.mostrarProductos.map((producto, index) => (
                  <Grid item key={index} lg={4} md={6} sm={6} xs={12}>
                    <Card plain product>
                      <CardHeader noShadow image>
                        <div style={{display:'flex',justifyContent:'center'}}>
                          <a style={{ cursor: 'pointer' }} onClick={() => this.ObtenerTallas(producto.Producto[0], producto.Producto[0].idCategoria, producto._id, producto)}>
                            <img style={{ width: '150px', height: '200px', objectFit: 'cover' }} src={producto.Producto[0].ImagenProducto != null ? "data:image/png;base64," + producto.Producto[0].ImagenProducto : null} alt=".." />
                          </a>
                        </div>
                      </CardHeader>
                      <CardBody plain>
                        <a href="#pablo">
                          <h4 className={classes.cardTitle}>{producto.Producto[0].NombreProducto}</h4>
                        </a>
                        <center>
                          <p className={classes.description}>
                            {producto.Producto[0].Descripcion}
                          </p>
                        </center>
                      </CardBody>
                      {/* Tu resto de contenido */}
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <div className={classes.pagination} style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <Pagination
                  count={Math.ceil(totalProductos / itemsPerPage)}
                  page={currentPage}
                  onChange={this.handlePageChange}
                  color="standard"
                  size="large"
                  showFirstButton
                  showLastButton
                  style={{
                    marginBottom: '20px',
                  }}
                />
              </div>
            </div>
          ) : (
            <div>
              <center>
                <h2>Cargando productos</h2>
                <Loader
                  visible={true}
                  type="Oval"
                  color="#212121"
                  height={40}
                  width={40}
                />
              </center>
            </div>
          )}
        </GridItem>
      </GridContainer>
    }

    return (
      <div className={classes.section} style={this.props.id == "SectionProducts" ? { background: `black` } : {}}>
        <div className={classes.container}>
          {accordion}

          {/**Modales */}
          {
            this.state.VerModalTallas ?
              <ModalTalla
                EventoModalTalla={() => this.setState({ VerModalTallas: false })}
                Tallas={this.state.Tallas}
                ProductosTallas={this.state.ProductosTallas}
                CaracteristicasPorTalla={this.state.CaracteristicasPorTalla}

                Producto={this.state.ProductoSeleccionado}
                VerModal={this.state.VerModalTallas}
              />
              : null
          }

          {
            this.state.modalImagen ?
              <ModalImagen
                EventoModalImagen={() => {
                  this.props.setOverflowXStyle('hidden');
                  this.setState({ modalImagen: false});
                }}
                Producto={this.state.ProductoSeleccionado}
                VerModal={this.state.modalImagen}
              />
              : null
          }
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SectionProducts);
