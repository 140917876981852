
// @material-ui/core components

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import Globales from "utils/Globales";
import Enumerable from 'linq';
import Card from "components/Card/Card.jsx";
import Slide from "@material-ui/core/Slide";
import withStyles from "@material-ui/core/styles/withStyles";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";
import DialogActions from "@material-ui/core/DialogActions";
import Salir from "@material-ui/icons/ExitToApp";
import Money from "@material-ui/icons/AttachMoney";

import MustangBadboy from '../../assets/img/terco-img/deseos/MustangBadboy.webp'
import MustangBlack from '../../assets/img/terco-img/deseos/MustangBlack.webp'
import MustangSunset from '../../assets/img/terco-img/deseos/MustangSunset.webp'
import { Fade } from 'react-slideshow-image';


const fadeProperties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: false,
    indicators: true,
    onChange: (oldIndex, newIndex) => {
      //console.log(`fade transition from ${oldIndex} to ${newIndex}`);
    }
  }

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class ModalVisorImagenes extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
         
          Imagenes:[],
        }
        this.escFunction = this.escFunction.bind(this);
  
    }
  

    escFunction(event) {
        if (event.keyCode === 27) {
            this.props.eventoModal()
        }
    }

    componentDidMount() {
    
        
        document.addEventListener("keydown", this.escFunction, false);


    }
    componentWillMount() {

    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

  
    

    render() {
        const { classes, ...rest } = this.props;
      
      
        return (
         
            <div >


                {/* SIGNUP MODAL START */}
                <Dialog
                    classes={{
                        root: classes.modalRoot,
                        paper: classes.modal + " " + classes.modalSignup
                    }}
                    id="mdlConfiguracionProducto"
                    open={this.props.verModal}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="singup-modal-slide-title"
                    aria-describedby="singup-modal-slide-description"
                >
                    <Card plain className={classes.modalSignupCard}>
                        <DialogTitle
                            id="signup-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                        >
                            <Button
                                simple
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                onClick={() => this.props.eventoModal()}
                            >
                                {" "}
                                <Close className={classes.modalClose} />
                            </Button>
                         
                        </DialogTitle>
                        <DialogContent id="signup-modal-slide-description"  className={classes.modalBody}>
                      
                  
                              
                        <div style={{textAlign:'center'}} className="slide-container">
      <Fade {...fadeProperties}>
        {
      this.props.Imagenes.map(imagen =>
        <div className="each-fade">
        <div className="image-container">
      
          <img width="40%" src={ imagen} />
          
        
        </div>
    
      </div>
      )
          }
        
      
        {/* <div className="each-fade">
          <div className="image-container">
            <img width="40%" src={MustangSunset} />
          </div>
      
        </div>
        <div className="each-fade">
          <div className="image-container">
            <img width="40%" src={MustangBlack} />
          </div>
        
        </div> */}
      </Fade>
    </div>
                                    
              
                        </DialogContent>
                      
                    </Card>
                </Dialog>
                {/* SIGNUP MODAL END */}
            </div >
            
        );
    }
}




export default withStyles(modalStyle)(ModalVisorImagenes);
