import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Link, HashRouter, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-pro-react.scss?v=1.3.0";

// pages for this product
import AboutUsPage from './views/AboutUsPage/AboutUsPage';
import BlogPostPage from './views/BlogPostPage/BlogPostPage';
import BlogPostsPage from './views/BlogPostsPage/BlogPostsPage';
import ComponentsPage from './views/ComponentsPage/ComponentsPage';
import ContactUsPage from './views/ContactUsPage/ContactUsPage';
import EcommercePage from './views/EcommercePage/EcommercePage';
import LandingPage from './views/LandingPage/LandingPage';
import LoginPage from './views/LoginPage/LoginPage';

//import PaginaPrincipal from './views/PresentationPage/Principal'

import PaginaPrincipal from './Componentes/PaginaPrincipal/Principal.jsx'
import Diseñador3D from './Componentes/Diseñador3D/Diseñador.jsx';

import PricingPage from './views/PricingPage/PricingPage';
import ProfilePage from './views/ProfilePage/ProfilePage';
//import ProductPage from './views/ProductPage/ProductPage';
import SectionsPage from './views/SectionsPage/SectionsPage';
import Carrito from './Componentes/Carrito/Carrito.jsx';
import Login from './Componentes/Login/Login.jsx';
import ErrorPage from './views/ErrorPage/ErrorPage';
import CartPage from "views/PlayerasPage/CartPage.jsx";
import playeras from "views/PlayerasPage/ProductDetailPage.jsx";
import ListaDeseos from './Componentes/ListaDeseos/ListaDeseos.jsx';
import ConfirmarCompra from './Componentes/ConfirmarCompra/ConfirmarCompra.jsx';
import SeleccionProducto from './Componentes/SeleccionProducto/SeleccionDeDiseño.jsx';
import Perfil from './Componentes/PerfilUsuario/Perfil.jsx';
import Artistas from './Componentes/Artistas/Artistas';

import GaleriaProductos from './views/Galeria/GaleriaProductos';
import AvisoPrivacidad from './Componentes/PaginaPrincipal/AvisoPrivacidad.jsx';
import TerminosCondiciones from './Componentes/PaginaPrincipal/TerminosCondiciones.jsx';
import Cookies from 'js-cookie'
var hist = createBrowserHistory();

ReactDOM.render(
  <HashRouter>
    <Switch>

      <Route path="/playeras" component={playeras} />
      <Route path="/carrito-compras" component={CartPage} />
      <Route path="/galeria-productos" component={GaleriaProductos} />
      <Route path="/about-us" component={AboutUsPage} />
      <Route path="/blog-post" component={BlogPostPage} />
      <Route path="/blog-posts" component={BlogPostsPage} />
      <Route path="/components" component={ComponentsPage} />
      <Route path="/contact-us" component={ContactUsPage} />
      <Route path="/ecommerce-page" component={EcommercePage} />
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/login-page" component={LoginPage} />
      <Route path="/pricing" component={PricingPage} />
      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/sections" component={SectionsPage} />
      {/* <Route path="/shopping-cart-page" component={ShoppingCartPage} /> */}

      <Route path="/error-page" component={ErrorPage} />

      {/**Nuevas rutas */}
      <Route path="/Artistas" component={Artistas} /> 

       <Route path="/Perfil" component={Perfil} /> 
      <Route path="/Tienda/:id" component={Perfil} />
      <Route path="/SeleccionaTuDiseño" component={SeleccionProducto} />
      <Route path="/lista-deseos" component={ListaDeseos} />
      <Route path="/confirmar-compra" component={ConfirmarCompra} />
      <Route path="/login" component={Login} />
      <Route path="/carrito" component={Carrito} />
      <Route path="/diseñador3D" component={Diseñador3D} />
      <Route path="/avisoPrivacidad" component={AvisoPrivacidad} />
      <Route path="/terminosCondiciones" component={TerminosCondiciones} />
      <Route path="/" component={PaginaPrincipal} />
    </Switch>
  </HashRouter>,
  document.getElementById("fb-root")
);
