/* eslint-disable */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
// core components
import Header from "components/Header/Header.jsx";
import Table from "components/Table/Table.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Badge from "components/Badge/Badge.jsx";
import Muted from "components/Typography/Muted.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Button from "components/CustomButtons/Button.jsx";

import christian from "assets/img/faces/christian.jpg";
import avatar from "assets/img/faces/avatar.jpg";
import marc from "assets/img/faces/marc.jpg";
import kendall from "assets/img/faces/kendall.jpg";
import cardProfile2Square from "assets/img/faces/card-profile2-square.jpg";


import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.jsx";

import Cookies from 'js-cookie'
import { Edit, ShoppingBasket, Favorite } from "@material-ui/icons";
import ModalEditar from './ModalEditar';

import product1 from '../../assets/img/terco-img/playera1.webp';

import product2 from '../../assets/img/terco-img/playera2.jpg';
import product3 from '../../assets/img/terco-img/playera3.jpg';

//Diseños
import diseno1 from '../../assets/img/terco-img/Crazy&Colors.webp';
import diseno2 from '../../assets/img/terco-img/RacingTracks.png';
import diseno3 from '../../assets/img/terco-img/RevolucionCannábica.webp';
import diseno4 from '../../assets/img/terco-img/SkullsWings&Pistons.webp';
import diseno5 from '../../assets/img/terco-img/WolfsFangs.webp';
import diseno6 from '../../assets/img/terco-img/VintageHarleyJersey.webp';




// @material-ui/core components
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// core components

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.eventoModal = this.eventoModal.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    //console.log(Cookies.get());
  }

  openModal = () => {
    this.setState({ show: true });
  }

  eventoModal(e) {
    if (!e) {
      this.setState({ show: false });
      return;
    }
    this.setState({ show: false })
    // this.cerrarSesion(this.seleccionado);
  }

  eventoClose = (mensaje, colorNotificacion) => {
    this.setState({ show: false });

    if (mensaje !== 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: colorNotificacion })
      this.showNotification();
    }
  }

  render() {
    const { classes, ...rest } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
    return (
      <div>
        <Header
          color="transparent"
          links={<HeaderLinks dropdownHoverColor="dark" />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "dark"
          }}
          {...rest}
        />
        <Parallax
          image={require("assets/img/examples/city.jpg")}
          filter="dark"
          className={classes.parallax}
        />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={christian} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    {Cookies.get('Nombre') !== '' ? <h3 className={classes.title}>{Cookies.get('Nombre')}</h3> : <h3 className={classes.title}>{Cookies.get('Usuario')}</h3>}
                    {Cookies.get('TipoUsuario') !== '' ? <h6>{Cookies.get('TipoUsuario')}</h6> : null}
                  </div>
                </div>
                <div className={classes.follow}>
                  <Tooltip
                    id="tooltip-top"
                    title="Editar Perfil"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      // href='/signup-page'
                      justIcon
                      round
                      color="info"
                      className={classes.followButton}
                      onClick={() => this.openModal()}
                    >
                      <Edit className={classes.followIcon} />
                    </Button>
                  </Tooltip>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.profileTabs}>
              <NavPills alignCenter color="info"
                tabs={[
                  {
                    tabButton: "Mis Compras",
                    tabIcon: ShoppingBasket,
                    tabContent: (
                      // <GridContainer>
                      //   <GridItem
                      //     xs={12}
                      //     sm={12}
                      //     md={7}
                      //     className={classes.gridItem}
                      //   >
                      //     <h4 className={classes.title}>Latest Collections</h4>
                      //     <GridContainer className={classes.collections}>
                      //       <GridItem xs={12} sm={12} md={6}>
                      //         <Card
                      //           background
                      //           style={{
                      //             backgroundImage:
                      //               "url(" + mariyaGeorgieva + ")"
                      //           }}
                      //         >
                      //           <a href="#pablo" />
                      //           <CardBody
                      //             background
                      //             className={classes.cardBody}
                      //           >
                      //             <Badge
                      //               color="warning"
                      //               className={classes.badge}
                      //             >
                      //               Spring 2016
                      //             </Badge>
                      //             <a href="#pablo">
                      //               <h2 className={classes.cardTitleWhite}>
                      //                 Stilleto
                      //               </h2>
                      //             </a>
                      //           </CardBody>
                      //         </Card>
                      //       </GridItem>
                      //       <GridItem xs={12} sm={12} md={6}>
                      //         <Card
                      //           background
                      //           style={{
                      //             backgroundImage: "url(" + clemOnojeghuo + ")"
                      //           }}
                      //         >
                      //           <a href="#pablo" />
                      //           <CardBody
                      //             background
                      //             className={classes.cardBody}
                      //           >
                      //             <Badge color="info" className={classes.badge}>
                      //               Spring 2016
                      //             </Badge>
                      //             <a href="#pablo">
                      //               <h2 className={classes.cardTitleWhite}>
                      //                 High Heels
                      //               </h2>
                      //             </a>
                      //           </CardBody>
                      //         </Card>
                      //       </GridItem>
                      //       <GridItem xs={12} sm={12} md={6}>
                      //         <Card
                      //           background
                      //           style={{
                      //             backgroundImage: "url(" + oluEletu + ")"
                      //           }}
                      //         >
                      //           <a href="#pablo" />
                      //           <CardBody
                      //             background
                      //             className={classes.cardBody}
                      //           >
                      //             <Badge
                      //               color="danger"
                      //               className={classes.badge}
                      //             >
                      //               Summer 2016
                      //             </Badge>
                      //             <a href="#pablo">
                      //               <h2 className={classes.cardTitleWhite}>
                      //                 Flats
                      //               </h2>
                      //             </a>
                      //           </CardBody>
                      //         </Card>
                      //       </GridItem>
                      //       <GridItem xs={12} sm={12} md={6}>
                      //         <Card
                      //           background
                      //           style={{
                      //             backgroundImage:
                      //               "url(" + darrenColeshill + ")"
                      //           }}
                      //         >
                      //           <a href="#pablo" />
                      //           <CardBody
                      //             background
                      //             className={classes.cardBody}
                      //           >
                      //             <Badge
                      //               color="success"
                      //               className={classes.badge}
                      //             >
                      //               Winter 2016
                      //             </Badge>
                      //             <a href="#pablo">
                      //               <h2 className={classes.cardTitleWhite}>
                      //                 Men's Sneakers
                      //               </h2>
                      //             </a>
                      //           </CardBody>
                      //         </Card>
                      //       </GridItem>
                      //     </GridContainer>
                      //   </GridItem>
                      //   <GridItem
                      //     xs={12}
                      //     sm={12}
                      //     md={2}
                      //     className={classes.gridItem}
                      //   >
                      //     <h4 className={classes.title}>Stats</h4>
                      //     <ul className={classes.listUnstyled}>
                      //       <li>
                      //         <b>60</b> Products
                      //       </li>
                      //       <li>
                      //         <b>4</b> Collections
                      //       </li>
                      //       <li>
                      //         <b>331</b> Influencers
                      //       </li>
                      //       <li>
                      //         <b>1.2K</b> Likes
                      //       </li>
                      //     </ul>
                      //     <hr />
                      //     <h4 className={classes.title}>About this work</h4>
                      //     <p className={classes.description}>
                      //       French luxury footwear and fashion. The footwear has
                      //       incorporated shiny, red-lacquered soles that have
                      //       become his signature.
                      //     </p>
                      //     <hr />
                      //     <h4 className={classes.title}>Focus</h4>
                      //     <Badge color="primary">Footwear</Badge>
                      //     <Badge color="rose">Luxury</Badge>
                      //   </GridItem>
                      // </GridContainer>
                      <div className={classNames(classes.main, classes.mainRaised)}>
                        <div className={classes.container}>
                          <Card plain>
                            <CardBody plain>
                              <h3 className={classes.cardTitle}> </h3>
                              <Table
                                tableHead={[
                                  "",
                                  "PRODUCTO",
                                  "COLOR",
                                  "TALLA",
                                  "PRECIO",
                                  "CANTIDAD",
                                  "PRECIO",
                                  ""
                                ]}
                                tableData={[
                                  [
                                    <div className={classes.imgContainer}>
                                      <img src={product1} width="150" height="150" alt="..." className={classes.img} />
                                    </div>,
                                    <span>
                                      <a href="#jacket" className={classes.tdNameAnchor}>
                                        Dark Desings
                                    </a>
                                      <br />
                                      <small className={classes.tdNameSmall}>
                                        by Terco
                                    </small>
                                    </span>,
                                    "Negro",
                                    "M",
                                    <span>
                                      <small className={classes.tdNumberSmall}>$</small> 549
                                  </span>,
                                    <span>
                                      1{` `}
                                      <div className={classes.buttonGroup}>
                                        <Button
                                          color="info"
                                          size="sm"
                                          round
                                          className={classes.firstButton}
                                        >
                                          <Remove />
                                        </Button>
                                        <Button
                                          color="info"
                                          size="sm"
                                          round
                                          className={classes.lastButton}
                                        >
                                          <Add />
                                        </Button>
                                      </div>
                                    </span>,
                                    <span>
                                      <small className={classes.tdNumberSmall}>$</small> 549
                                  </span>,
                                    <Tooltip
                                      id="close1"
                                      title="Remove item"
                                      placement="left"
                                      classes={{ tooltip: classes.tooltip }}
                                    >
                                      <Button link className={classes.actionButton}>
                                        <Close />
                                      </Button>
                                    </Tooltip>
                                  ],
                                  [
                                    <div className={classes.imgContainer}>
                                      <img src={product2} width="150" height="150" alt="..." className={classes.img} />
                                    </div>,
                                    <span>
                                      <a href="#jacket" className={classes.tdNameAnchor}>
                                        Dark Jungle{" "}
                                      </a>
                                      <br />
                                      <small className={classes.tdNameSmall}>by Terco</small>
                                    </span>,
                                    "Negro",
                                    "M",
                                    <span>
                                      <small className={classes.tdNumberSmall}>$</small> 499
                                  </span>,
                                    <span>
                                      2{` `}
                                      <div className={classes.buttonGroup}>
                                        <Button
                                          color="info"
                                          size="sm"
                                          round
                                          className={classes.firstButton}
                                        >
                                          <Remove />
                                        </Button>
                                        <Button
                                          color="info"
                                          size="sm"
                                          round
                                          className={classes.lastButton}
                                        >
                                          <Add />
                                        </Button>
                                      </div>
                                    </span>,
                                    <span>
                                      <small className={classes.tdNumberSmall}>$</small> 998
                                  </span>,
                                    <Tooltip
                                      id="close2"
                                      title="Remove item"
                                      placement="left"
                                      classes={{ tooltip: classes.tooltip }}
                                    >
                                      <Button link className={classes.actionButton}>
                                        <Close />
                                      </Button>
                                    </Tooltip>
                                  ],
                                  [
                                    <div className={classes.imgContainer}>
                                      <img src={product3} width="150" height="150" alt="..." className={classes.img} />
                                    </div>,
                                    <span>
                                      <a href="#jacket" className={classes.tdNameAnchor}>
                                        Mustang Badboy
                                    </a>
                                      <br />
                                      <small className={classes.tdNameSmall}>
                                        by Terco
                                    </small>
                                    </span>,
                                    "Negro",
                                    "XL",
                                    <span>
                                      <small className={classes.tdNumberSmall}>$</small> 799
                                  </span>,
                                    <span>
                                      1{` `}
                                      <div className={classes.buttonGroup}>
                                        <Button
                                          color="info"
                                          size="sm"
                                          round
                                          className={classes.firstButton}
                                        >
                                          <Remove />
                                        </Button>
                                        <Button
                                          color="info"
                                          size="sm"
                                          round
                                          className={classes.lastButton}
                                        >
                                          <Add />
                                        </Button>
                                      </div>
                                    </span>,
                                    <span>
                                      <small className={classes.tdNumberSmall}>$</small> 799
                                  </span>,
                                    <Tooltip
                                      id="close3"
                                      title="Remove item"
                                      placement="left"
                                      classes={{ tooltip: classes.tooltip }}
                                    >
                                      <Button link className={classes.actionButton}>
                                        <Close />
                                      </Button>
                                    </Tooltip>
                                  ],
                                  {
                                    purchase: true,
                                    colspan: "3",
                                    amount: (
                                      <span>
                                        <small>$</small>2,346
                                    </span>
                                    ),
                                    col: {
                                      colspan: 3,
                                      text: (
                                        <Button color="info" round>
                                          Finalizar Compra <KeyboardArrowRight />
                                        </Button>
                                      )
                                    }
                                  }
                                ]}
                                tableShopping
                                customHeadCellClasses={[
                                  classes.textCenter,
                                  classes.description,
                                  classes.description,
                                  classes.textRight,
                                  classes.textRight,
                                  classes.textRight
                                ]}
                                customHeadClassesForCells={[0, 2, 3, 4, 5, 6]}
                                customCellClasses={[
                                  classes.tdName,
                                  classes.customFont,
                                  classes.customFont,
                                  classes.tdNumber,
                                  classes.tdNumber + " " + classes.tdNumberAndButtonGroup,
                                  classes.tdNumber + " " + classes.textCenter
                                ]}
                                customClassesForCells={[1, 2, 3, 4, 5, 6]}
                              />
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    )
                  },
                  {
                    tabButton: "Mis Diseños",
                    tabIcon: Favorite,
                    tabContent: (
                      <div>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={5} className={classes.gridItem}>
                            <Card profile plain className={classes.card}>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={5}>
                                  <CardHeader image plain>
                                    <a href='/product-page'>
                                      <img src={diseno1} alt="..." />
                                    </a>
                                    <div
                                      className={classes.coloredShadow}
                                      style={{
                                        backgroundImage: "url(" + avatar + ")",
                                        opacity: "1"
                                      }}
                                    />
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={7}>
                                  <CardBody plain>
                                    <h4 className={classes.cardTitle}>
                                      Crazy & Colors
                                    </h4>
                                    <Button href='/product-page' size='sm' round color='default'><Edit />Editar Diseño</Button>
                                    {/* <Muted>
                                      <h6>MODEL</h6>
                                    </Muted> */}
                                    {/* <p className={classes.description}>
                                      Don't be scared of the truth because we
                                      need to restart the human foundation in
                                      truth...
                                    </p> */}
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={5} className={classes.gridItem}>
                            <Card profile plain className={classes.card}>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={5}>
                                  <CardHeader image plain>
                                    <a href='/product-page'>
                                      <img src={diseno2} alt="..." />
                                    </a>
                                    <div
                                      className={classes.coloredShadow}
                                      style={{
                                        backgroundImage: "url(" + marc + ")",
                                        opacity: "1"
                                      }}
                                    />
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={7}>
                                  <CardBody plain>
                                    <h4 className={classes.cardTitle}>
                                      Racing Tracks
                                    </h4>
                                    <Button href='/product-page' size='sm' round color='default'><Edit />Editar Diseño</Button>
                                    {/* <Muted>
                                      <h6>DESIGNER</h6>
                                    </Muted>
                                    <p className={classes.description}>
                                      Don't be scared of the truth because we
                                      need to restart the human foundation in
                                      truth...
                                    </p> */}
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={5}
                            className={classes.gridItem}
                          >
                            <Card profile plain className={classes.card}>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={5}>
                                  <CardHeader image plain>
                                    <a href='/product-page'>
                                      <img src={diseno3} alt="..." />
                                    </a>
                                    <div
                                      className={classes.coloredShadow}
                                      style={{
                                        backgroundImage: "url(" + marc + ")",
                                        opacity: "1"
                                      }}
                                    />
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={7}>
                                  <CardBody plain>
                                    <h4 className={classes.cardTitle}>
                                    Revolucion Cannábica
                                    </h4>
                                    <Button href='/product-page' size='sm' round color='default'><Edit />Editar Diseño</Button>
                                    {/* <Muted>
                                      <h6>DESIGNER</h6>
                                    </Muted>
                                    <p className={classes.description}>
                                      Don't be scared of the truth because we
                                      need to restart the human foundation in
                                      truth...
                                    </p> */}
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={5}
                            className={classes.gridItem}
                          >
                            <Card profile plain className={classes.card}>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={5}>
                                  <CardHeader image plain>
                                    <a href='/product-page'>
                                      <img src={diseno4} alt="..." />
                                    </a>
                                    <div
                                      className={classes.coloredShadow}
                                      style={{
                                        backgroundImage: "url(" + marc + ")",
                                        opacity: "1"
                                      }}
                                    />
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={7}>
                                  <CardBody plain>
                                    <h4 className={classes.cardTitle}>
                                    Skulls Wings & Pistons
                                    </h4>
                                    <Button href='/product-page'size='sm' round color='default'><Edit />Editar Diseño</Button>
                                    {/* <Muted>
                                      <h6>DESIGNER</h6>
                                    </Muted>
                                    <p className={classes.description}>
                                      Don't be scared of the truth because we
                                      need to restart the human foundation in
                                      truth...
                                    </p> */}
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={5}
                            className={classes.gridItem}
                          >
                            <Card profile plain className={classes.card}>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={5}>
                                  <CardHeader image plain>
                                    <a href='/product-page'>
                                      <img src={diseno5} alt="..." />
                                    </a>
                                    <div
                                      className={classes.coloredShadow}
                                      style={{
                                        backgroundImage: "url(" + marc + ")",
                                        opacity: "1"
                                      }}
                                    />
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={7}>
                                  <CardBody plain>
                                    <h4 className={classes.cardTitle}>
                                    Wolf's Fangs
                                    VintageHarleyJersey
                                    </h4>
                                    <Button href='/product-page' size='sm' round color='default'><Edit />Editar Diseño</Button>
                                    {/* <Muted>
                                      <h6>DESIGNER</h6>
                                    </Muted>
                                    <p className={classes.description}>
                                      Don't be scared of the truth because we
                                      need to restart the human foundation in
                                      truth...
                                    </p> */}
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={5}
                            className={classes.gridItem}
                          >
                            <Card profile plain className={classes.card}>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={5}>
                                  <CardHeader image plain>
                                    <a href='/product-page'>
                                      <img src={diseno6} alt="..." />
                                    </a>
                                    <div
                                      className={classes.coloredShadow}
                                      style={{
                                        backgroundImage: "url(" + marc + ")",
                                        opacity: "1"
                                      }}
                                    />
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={7}>
                                  <CardBody plain>
                                    <h4 className={classes.cardTitle}>                                    
                                    Vintage Harley Jersey
                                    </h4>
                                    <Button href='/product-page' size='sm' round color='default'><Edit />Editar Diseño</Button>
                                    {/* <Muted>
                                      <h6>DESIGNER</h6>
                                    </Muted>
                                    <p className={classes.description}>
                                      Don't be scared of the truth because we
                                      need to restart the human foundation in
                                      truth...
                                    </p> */}
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </div>
                    )
                  }
                  // ,
                  // {
                  //   tabButton: "Media",
                  //   tabIcon: Camera,
                  //   tabContent: (
                  //     <GridContainer justify="center">
                  //       <GridItem xs={12} sm={12} md={3}>
                  //         <img
                  //           alt="..."
                  //           src={mariyaGeorgieva}
                  //           className={navImageClasses}
                  //         />
                  //         <img
                  //           alt="..."
                  //           src={clemOnojegaw}
                  //           className={navImageClasses}
                  //         />
                  //       </GridItem>
                  //       <GridItem xs={12} sm={12} md={3}>
                  //         <img
                  //           alt="..."
                  //           src={clemOnojeghuo}
                  //           className={navImageClasses}
                  //         />
                  //         <img
                  //           alt="..."
                  //           src={oluEletu}
                  //           className={navImageClasses}
                  //         />
                  //         <img
                  //           alt="..."
                  //           src={cynthiaDelRio}
                  //           className={navImageClasses}
                  //         />
                  //       </GridItem>
                  //     </GridContainer>
                  //   )
                  // }
                ]}
              />
            </div>
            <Clearfix />
          </div>
        </div>
        <div id="">
          <ModalEditar
            Titulo='Editar Perfil'
            liveDemo={this.state.show}
            size='large'
            eventClick={this.eventoModal}
            eventClose={this.eventoClose}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(profilePageStyle)(ProfilePage);
