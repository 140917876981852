import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import Loader from 'react-loader-spinner'
import { Grid } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ModalCargando extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick()
    }
  }

 

 
 
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: this.props.size === 'large' ? (classes.modal + ' ' + classes.modalLarge) : classes.modal
      }}
          open={this.props.Ver}
        TransitionComponent={Transition}
        keepMounted
        
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
            <GridContainer>
                <GridItem lg={12} >
                    <div>
                        <center>
                        <h2>{this.props.Cuerpo}</h2>
              <Loader visible={true} type="ThreeDots" color="#212121" height={30} width={50} />

                        </center>

                    </div>
                </GridItem>
            </GridContainer>
              
          
              


        </DialogContent>
    
        </Dialog>
      </div >
    );
  }
}

export default withStyles(modalStyle)(ModalCargando);