import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/latestOffersStyle.jsx";
import StyleBasics from '../Generales/Styles/StyleBasics.css';



import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Carrito from "@material-ui/icons/AddShoppingCart";
import ModalDetalles from './ModalDetallesProducto.jsx';
import ModalConfiguracionProducto from '../ConfiguracionProducto/ModalConfiguracionProducto.jsx';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Cargando from "../Generales/Cargando.jsx";
import MustangBadboy from '../../assets/img/terco-img/deseos/MustangBadboy.webp'





import Globales from "utils/Globales";
import Cookies from 'js-cookie'
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

const Direcctorio = 'Productos/'
class SeccionListaDeseos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cargando: false,
      productosDeseos: [],
      idCliente: '',
      idModelo: '5d88e669c177b44394910f2b',
      VerModalDetalles: false,
      VerModalConfiguracion: false,
      Botones: {
        BotonesCliente: [
          { color: "github", icon: Carrito, Msj: "Comprar" },
          { color: "github", icon: Edit, Msj: "Diseñar" },
          { color: "github", icon: Close, Msj: "Eliminar" }
        ],
        BotonesArtista: [
          { color: "github", icon: Carrito, Msj: "Comprar" },
          { color: "github", icon: Edit, Msj: "Diseñar" },
          { color: "github", icon: Add, Msj: "Agregar a tienda" },
          { color: "github", icon: Close, Msj: "Eliminar" }
        ]
      }
    };
    this.eventoModalCerrar = this.eventoModalCerrar.bind(this);
    this.eventoModalConfiguracion = this.eventoModalConfiguracion.bind(this)

  }

  componentDidMount() {
    //5d88e669c177b44394910f2b
    this.setState({ cargando: true });
    this.obtenerProductosDeseos();

  }


  obtenerProductosDeseos() {

    fetch(Globales.Url + 'ListaDeseos/ListaDeseos/ObtenerProductosListaDeseos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({ idCliente: JSON.parse(Cookies.get('UsuarioTerco')).id })
    })
      .then(res => res.json())
      .then(data => {
      //console.log("TCL: data", data)
        if (data.error) { //Error al obtener los registros
          this.setState({ productosDeseos: [] });
        } else { //Obtuvo los registros correctamente
          /**verificar rango de precios */
          var numeros = [];
          data.data.map(m => {
            m.abanico.map(a => {
              numeros.push(a.PrecioPublico)
            })
            m["minimo"] = Math.min.apply(Math, numeros)
            m["maximo"] = Math.max.apply(Math, numeros)
            if (m.minimo == m.maximo) {
              m["Bandera"] = true;
            } else {
              m["Bandera"] = false;
            }
          })
          this.setState({ productosDeseos: data.data }, () => { this.setState({ cargando: false }) }
          );
        }
      });
  }
  eventoModalCerrar() {
    this.setState({
      VerModalDetalles: false
    })

  }
  eventoModalConfiguracion(e) {

    if (e == false) {
      this.showNotification("Producto agregado al carrito", "success")
      this.ObtenerCarrito();
    }
    this.setState({
      VerModalConfiguracion: false
    })



  }
  ObtenerCarrito() {

    fetch(Globales.Url + 'Carrito/Carrito/ConsultarCarrito', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({ idCliente: JSON.parse(Cookies.get('UsuarioTerco')).id })
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros

        } else { //Obtuvo los registros correctamente
          var galleta = JSON.parse(Cookies.get("UsuarioTerco"));
          galleta.Carrito = data.data.length;
          Cookies.set('UsuarioTerco', JSON.stringify(galleta));
          this.props.ActualizarCarrito()


        }
      });
  }
  EliminarProductoListaDeseos() {

    fetch(Globales.Url + 'Carrito/Carrito/ConsultarCarrito', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({ idCliente: JSON.parse(Cookies.get('UsuarioTerco')).id })
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros

        } else { //Obtuvo los registros correctamente
          var galleta = JSON.parse(Cookies.get("UsuarioTerco"));
          galleta.Carrito = data.data.length;
          Cookies.set('UsuarioTerco', JSON.stringify(galleta));
          this.props.ActualizarCarrito()


        }
      });
  }

  showNotification(mensaje, color) {
    this.setState({
      colorNotificacion: color,
      mensaje: mensaje,
    })
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      3000
    );
  }


  AccionBotones(Opcion) {

    if (Opcion == "Comprar") {
      this.setState({
        VerModalConfiguracion: true
      })
    }
    if (Opcion == "Diseñar") {
      window.location.href = "/diseñador3D"
    }
    if (Opcion == "Eliminar") {

    }
    if (Opcion == "Agregar a tienda") {

    }



  }
  CambiarFormatoPrecio(precio) {
    var num = '$' + parseFloat(precio, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
    return num;
  }


  render() {
    const { classes } = this.props;

    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <h2>Lista de deseos</h2>
          <GridContainer >
            {
              this.state.cargando == true ? <GridItem> <GridContainer   justify="center"><Cargando /> </GridContainer></GridItem>  :

                this.state.productosDeseos.length > 0 ?
                  this.state.productosDeseos.map(producto => {
                    return (


                      <GridItem xs={4} md={4} sm={4} lg={4}>
                        <GridContainer>

                          <GridItem xs={8} md={8} sm={8} lg={8} >
                            <Card   product >
                              <CardHeader image plain>

                                <div onMouseOver={() => this.setState({ [producto.diseñoProducto._id]: true })} onMouseLeave={() => this.setState({ [producto.diseñoProducto._id]: false })} id="ContenedorImagenListaDeseos" className="ContenedorImagenListaDeseos">
                                  <img style={{ height: '300px' }} src={MustangBadboy} alt="404" />
                                  {
                                    this.state[producto.diseñoProducto._id] ? <button onClick={() => this.setState({ VerModalDetalles: true })} id="btnImagen" className="btnImagen">Detalles</button> : null
                                  }


                                </div>

                              </CardHeader>
                              
                              <CardBody  className={classes.textCenter}>
                         
                                <h4 className={classes.cardTitle}>{producto.diseñoProducto.nombre}</h4>
                                <h4 className={classes.cardTitle}>{producto.diseñoProducto.descripcion}</h4>
                                <div className={classes.priceContainer}>

                                  <span className={classNames(classes.price, classes.priceNew)}>
                                    {" "}
                                    {
                                      producto.Bandera == true ? this.CambiarFormatoPrecio(producto.minimo) : this.CambiarFormatoPrecio(producto.minimo) + " - " + this.CambiarFormatoPrecio(producto.maximo)
                                    }

                                  </span>
                                </div>
                                <br />
                             

                              </CardBody>
                              <CardFooter plain></CardFooter>
                            </Card>
                          </GridItem>
                          <GridItem xs={4} md={4} sm={4} lg={4}>

                            <Card product plain>
                              <CardHeader image plain>
                                {
                                  this.state.Botones.BotonesCliente.map((prop, key) => {
                                    return (
                                      <GridContainer>
                                        <Tooltip id="tooltip-top" title={prop.Msj} placement="top" classes={{ tooltip: classes.tooltip }}>
                                          <Button onClick={() => this.AccionBotones(prop.Msj)} justIcon size="md" color={prop.color} key={key}>
                                            <prop.icon />
                                          </Button>
                                        </Tooltip>
                                      </GridContainer>


                                    )
                                  }
                                  )
                                }

                              </CardHeader>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    )
                  }

                  )
                  : <h2>No tienes productos en tu lista de deseos</h2>
            }

            


{/* 
<GridItem xs={4} md={4} sm={4} lg={4}>
                    <GridContainer>
                  
                        <GridItem xs={8} md={8} sm={8} lg={8} >
                          <Card product plain>
                            <CardHeader image plain>

                              <div onMouseOver={() => this.setState({ VerBoton1: true })} onMouseLeave={() => this.setState({ VerBoton1: false })} id="ContenedorImagenListaDeseos" className="ContenedorImagenListaDeseos">
                                <img src={MustangBadboy} alt="Snow" height="100%" />
                                {
                                  this.state.VerBoton1 ? <button onClick={() => this.setState({ VerModalDetalles: true })} id="btnImagen" className="btnImagen">Detalles</button> : null
                                }


                              </div>

                            </CardHeader>
                            <CardBody className={classes.textCenter} plain>
                              <h4 className={classes.cardTitle}>13123</h4>
                              <h4 className={classes.cardTitle}>Camisa lobo</h4>
                              <div className={classes.priceContainer}>

                                <span className={classNames(classes.price, classes.priceNew)}>
                                  {" "}
                                  $350.00
       </span>
                              </div>
                              <br />

                            </CardBody>
                            <CardFooter plain></CardFooter>
                          </Card>
                        </GridItem>
                        <GridItem xs={4} md={4} sm={4} lg={4}>

                          <Card product plain>
                            <CardHeader image plain>
                              {
                                this.state.Botones.BotonesCliente.map((prop, key) => {
                                  return (
                                    <GridContainer>
                                    <Tooltip id="tooltip-top" title={prop.Msj} placement="top" classes={{ tooltip: classes.tooltip }}>
                                      <Button onClick={() => this.AccionBotones(prop.Msj)} justIcon size="md" color={prop.color} key={key}>
                                        <prop.icon />
                                      </Button>
                                    </Tooltip>
                                    </GridContainer>


                                  )
                                }
                                )
                              }

                            </CardHeader>
                          </Card>

                      </GridItem>
                    </GridContainer>





                  </GridItem> */}
          </GridContainer>

          {this.state.VerModalConfiguracion ? <ModalConfiguracionProducto idModelo={this.state.idModelo} eventoModal={this.eventoModalConfiguracion} verModal={this.state.VerModalConfiguracion} /> : null}


          {this.state.VerModalDetalles ? <ModalDetalles idModelo={this.state.idModelo} eventoModal={this.eventoModalCerrar} verModal={this.state.VerModalDetalles} /> : null}
          <Snackbar
            place="tc"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );

  }
}
export default withStyles(styles)(SeccionListaDeseos);