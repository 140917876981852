import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Reply from "@material-ui/icons/Reply";
import Favorite from "@material-ui/icons/Favorite";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Media from "components/Media/Media.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import ReactStars from 'react-stars' /*estrellitas */
import profile4 from "assets/img/faces/card-profile4-square.jpg";
import profile1 from "assets/img/faces/card-profile1-square.jpg";
import profile6 from "assets/img/faces/card-profile6-square.jpg";

import sectionCommentsStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionCommentsStyle.jsx";

function SectionComments({ ...props }) {
  const { classes } = props;
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={10} md={8}>
          <div>
            <h3 className={classes.title}>2 Reseñas</h3>
            <Media
              avatar={profile4}
              title={
                <span>
                  Rosa Gutierrez <small>· 16 ene 2019</small>
                </span>
              }
              body={
                <p className={classes.color555}>
                 Una opción muy cómoda que sirve para entrenar y para el diario. Yo tengo 2 y son muy versatiles para mi. Las recomiendo ampliamente.
                </p>
              }
              footer={
                <div>
                 
                 <ReactStars
                        count={5}
                        value={3}
                        edit={false}
                        size={24}
                        color2={'#ffd700'} />
                </div>
              }
            />
            <Media
              avatar={profile1}
              title={
                <span>
                  Rodrigo Perez <small>· 10 ene 2019</small>
                </span>
              }
              body={
                <span className={classes.color555}>
                  <p>
                  Esta algo aguada pero ahi dice talla loose compre la talla  me sirve para entrenar me gusto tanto que compre de los demas colores
                  </p>
                </span>
              }
              footer={
                <div>
                <ReactStars
                        count={5}
                        value={5}
                        edit={false}
                        size={24}
                        color2={'#ffd700'} />
                </div>
              }
            />
          </div>
          <h3 className={classes.title}>Escribe una reseña</h3>
          <Media
            avatar={profile6}
            body={
              <CustomInput
                labelText="Cuentanos que te pareció..."
                id="nice"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  multiline: true,
                  rows: 5
                }}
              />
            }
            footer={
                <div>
                <ReactStars
                count={5}
                value={5}
                size={24}
                color2={'#ffd700'} />
              <Button color="default" round className={classes.footerButtons}>
                Enviar
              </Button>
              </div>
            }
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(sectionCommentsStyle)(SectionComments);
