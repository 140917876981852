import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Favorite from "@material-ui/icons/Favorite";
import Face from "@material-ui/icons/Face";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Warning from "@material-ui/icons/Warning";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import Info from "components/Typography/Info.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";

import image from "assets/img/bg7.jpg";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Nombre: '',
      Correo: '',
      Contrasena: '',
      ConfirmacionContrasena: '',
      TipoUsuario: '',
      CuentaBancaria: '',
      Telefono: '',
      Usuario: '',
      mensaje: '',
      br: false,
      colorNotificacion: 'success',
      errorNombre: false,
      errorCorreo: false,
      errorTelefono: false,
      errorCuentaBancaria: false,
      errorUsuario: false,
      errorContrasena: false,
      errorConfirmacionContrasena: false,
      errorTipoUsuario: false
    };
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          absolute
          color="transparent"
          links={<HeaderLinks dropdownHoverColor="dark" />}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <form className={classes.form}>
                    <CardHeader
                      color="primary"
                      signup
                      className={classes.cardHeader}
                    >
                      <h4 className={classes.cardTitle}>Login</h4>
                    </CardHeader>
                    <CardBody signup>
                    {/* <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={this.state.errorTipoUsuario}
                    className="mt-4"
                  >
                    <InputLabel
                      htmlFor="TipoUsuario"
                      className={classes.selectLabel}>
                      Tipo de Usuario
                        </InputLabel>
                    <Select
                      value={this.state.TipoUsuario}
                      //  value = {global.NombreMateriaPrima}
                      onChange={this.handleSimple}
                      name="TipoUsuario"
                      id="TipoUsuario"
                      inputProps={{
                      }}
                    >
                      <MenuItem disabled>Tipo de Usuario</MenuItem>
                      <MenuItem value='Cliente'>Cliente</MenuItem>
                      <MenuItem value='Artista'>Artista</MenuItem>
                    </Select>
                  </FormControl> */}
                      <CustomInput
                        id="Usuario"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          placeholder: "Usuario",
                          type: "text",
                          error: this.state.errorUsuario
                          // startAdornment: (
                          //   <InputAdornment position="start">
                          //     <Face className={classes.inputIconsColor} />
                          //   </InputAdornment>
                          // )
                        }}
                      />
                      {/* <CustomInput
                        id="Correo"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          placeholder: "Correo",
                          type: "email",
                          error: this.state.errorCorreo
                          // startAdornment: (
                          //   <InputAdornment position="start">
                          //     <Email className={classes.inputIconsColor} />
                          //   </InputAdornment>
                          // )
                        }}
                      /> */}
                      <CustomInput
                        id="pass"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          placeholder: "Password",
                          type: "password",
                          error: this.state.errorContrasena
                          // startAdornment: (
                          //   <InputAdornment position="start">
                          //     <Icon className={classes.inputIconsColor}>
                          //       lock_utline
                          //     </Icon>
                          //   </InputAdornment>
                          // )
                        }}
                      />
                    </CardBody>
                    <GridContainer justify='center'>
                      <a href="#pablo">Registrate ahora</a>
                    </GridContainer>
                    <div className={classes.textCenter}>
                      <Button round color="success" >
                        Iniciar Sesión
                      </Button>
                    </div>
                  </form>
                  {/* <CardFooter profile className={classes.justifyContentCenter}>

                    <Button justIcon round color="twitter">
                      <i className="fab fa-twitter" />
                    </Button>
                    <Button justIcon round color="facebook">
                      <i className="fab fa-facebook" />
                    </Button>
                    <Button justIcon round color="google">
                      <i className="fab fa-google" />
                    </Button>
                  </CardFooter> */}
                </Card>
              </GridItem>
            </GridContainer>
          </div>

        </div>
      </div>
    );
  }
}

export default withStyles(loginPageStyle)(LoginPage);
