import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// core components
import Header from "../Generales/Header/Header.jsx";
import HeaderLinks from "../Generales/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";


import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.jsx";

import Globales from "utils/Globales";
import Cookies from 'js-cookie'
import Cargando from "../Generales/Cargando.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Conekta from "conekta-card-tokenizer";
import FormasDePago from "./FormasDePago.jsx";
import ModalCargando from "../Generales/Modales/ModalCargando.jsx"
import ModalExito from "./ModalExito";

import { EnviarMail, ContenidoMail, getInfoImagen } from '../Generales/Controles/EnvioMail';



//key_MBKGL9mSC19nTULD8jcfCdQ




const conektaHelper = {
    initConekta: () => {
        window.Conekta.setPublicKey(Globales.KeyConekta)
    },
    getCardBrand: (cardNumber) => {
        return window.Conekta.card.getBrand(cardNumber)
    },
    validateCardNumber: (cardNumber) => {
        return window.Conekta.card.validateNumber(cardNumber)
    },
    validateCvc: (cvc) => {
        return window.Conekta.card.validateCVC(cvc)
    },
    validateExpirationDate: (expiryMonth, expiryYear) => {
        return window.Conekta.card.validateExpirationDate(expiryMonth, `20${expiryYear}`)
    },
    getBrand(cardNumber) {
        return window.Conekta.card.getBrand(cardNumber)

    },

    tokenize: (cardNumber, cardHolder, expiryMonth, expiryYear, cvc, successCallback, errorCallback) => {
        const tokenParams = {
            card: {
                number: cardNumber,
                name: cardHolder,
                exp_year: expiryYear,
                exp_month: expiryMonth,
                cvc


            }
        }
        window.Conekta.Token.create(tokenParams, successCallback, errorCallback)
    }
}


class ShoppingCartPage extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.AgregarQuitarCantidadProducto = this.AgregarQuitarCantidadProducto.bind(this)
        this.successCallback = this.successCallback.bind(this)
        this.RecibirDatosCompra = this.RecibirDatosCompra.bind(this)
        this.errorCallback = this.errorCallback.bind(this)

        this.state = {
            VerModalExito: false,
            imagenCargo: "",
            FormValido: false,
            VerModalCompra: false,
            ListaCarrito: [],
            NombreAtributo: 0,
            cargando: false,
            Token: "",
            DatosCompra: null,
            FormaDePago: null,
            DatosTarjeta: {
                NumeroTarjeta: "",
                NombreTitular: "",
                Mes: "",
                Año: "",
                CodigoSeguridad: "",
            }

        };

        /**variables globales para totales */
        global.Total = 0;
        global.Subtotal = 0;
        global.IVA = 0;
        global.Descuento = 0;
        global.Envio = 0;
        global.Saldo = 0;
        global.TotalProductos = 0;
    }

    componentDidMount() {

        this.state.DatosCompra = JSON.parse(localStorage.getItem("ValoresParaCompra"))
        if (this.state.DatosCompra == undefined || this.state.DatosCompra == null)  {
            window.location.href = "/#/";
            return;
        }

        const script = document.createElement('script')
        script.src = 'https://cdn.conekta.io/js/latest/conekta.js'
        script.async = true
        document.body.appendChild(script)

        conektaHelper.initConekta();


        this.state.ListaCarrito = this.state.DatosCompra.Productos;
        global.Saldo = this.state.DatosCompra.Saldo;
        // global.Envio = (this.state.DatosCompra.shipping_lines[0].amount / 100)
        global.Envio = (this.state.DatosCompra.shipping_lines[0].amount == null)?(0):(this.state.DatosCompra.shipping_lines[0].amount / 100)

        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
     //   this.setState({ cargando: true });
        this.CalcularTotales();

        if(global.Total == 0){
            this.setState({
                FormaDePago:0,
                VerModalCompra:true
            }, () =>{
                this.Comprar();

            })
        }
        
    }

    
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: parseInt(value) });
    }
    successCallback(token) {
        //this.state.DatosCompra.charges[0].payment_method.token_id = token.id;
        //this.state.DatosCompra.charges[0].payment_method.type = "card";
        //console.log(token)
        this.state.DatosCompra.charges[0] = {
            payment_method: {
                type: "card",
                token_id: token.id
            }
        }
        this.Comprar()

    }
    errorCallback(err) {
        //console.log(err)
        // this.state.DatosCompra.charges[0] = {
        //     payment_method: {
        //         type: "card",
        //         token_id: "1234"
        //     }
        // }
        //this.Comprar()
        //    var contenido =  ContenidoMail("", "", "");
        //    EnviarMail("arath890@gmail.com", 'compra exitosa', contenido);
        this.showNotification(err.message_to_purchaser, "warning")
        this.setState({
            VerModalCompra: false
        });

    }
    ObtenerTokenTarjeta() {
        var tarjeta = this.state.DatosTarjeta;
        //console.log(tarjeta)
        conektaHelper.tokenize(
            tarjeta.NumeroTarjeta, 
            tarjeta.NombreTitular, 
            tarjeta.Mes, 
            tarjeta.Año, 
            tarjeta.CodigoSeguridad, 
            this.successCallback, 
            this.errorCallback
        );
    }
    Comprar() {
        // this.ObtenerTokenTarjeta(datos.NumeroTarjeta,datos.NombreTitular,datos.Mes,datos.Año,datos.Codigo)
        //OXXO sin tarjeta

        var diseno = this.state.DatosCompra.Productos[0].idDiseno;
        var productos = [];
        var tituloCorreo = "Compra exitosa";
        // var productos = this.state.DatosCompra.Productos[0].idModelo;
        // productos["Diseno"]=this.state.DatosCompra.Productos[0].idDiseno;

        this.state.DatosCompra.Productos.map((m, index) => {
            productos.push(m.idModelo)
            productos[index]["Diseno"] = this.state.DatosCompra.Productos[index].idDiseno;
            productos[index]["Cantidad"] = m.Cantidad;
            productos[index]["ComisionEntregada"] = false;

        })


        var metodo = "";
        if (this.state.FormaDePago == 0) { metodo = "ConfirmarCompraTarjeta" }
        if (this.state.FormaDePago == 1) {
            metodo = "ConfirmarCompraOXXO"
            //oxxo_cash
        }
        if(this.state.FormaDePago == 2){
            metodo = "CorfirmarTransferencia"
        }
        var compraSaldo=null;
        if(global.Saldo>0){
            compraSaldo={Saldo:global.Saldo,idCliente:JSON.parse(Cookies.get("UsuarioTerco")).id}
        }
        // if (this.state.FormaDePago == 3) { }
        fetch(Globales.Url + 'Carrito/Carrito/' + metodo, {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }, body: JSON.stringify({
                NombreCliente:this.state.DatosTarjeta.NombreTitular,
                idCliente: JSON.parse(Cookies.get("UsuarioTerco")).id,
                DatosCompra: this.state.DatosCompra,
                Productos: productos,
                Domicilio: this.state.DatosCompra.Domicilio,
                TipoUsuario: JSON.parse(Cookies.get("UsuarioTerco")).TipoUsuario,
                tokenCard: this.state.DatosCompra.charges[0].payment_method.token_id,
                CompraConSaldo:compraSaldo,
                TotalVenta:global.Total,
                //PaqueteriaSeleccionada: this.state.PaqueteriaSeleccionada
            })
        })
            .then(res => res.json())
            .then(data => {
                //console.log(data)
                if (data.error) { //Error al obtener los registros
                    this.setState({
                        VerModalCompra: false
                    });
                    this.showNotification(data.mensaje, "danger")

                } else { //Obtuvo los registros correctamente
                    //  this.showNotification(data.mensaje, "success")
                    if(metodo == "ConfirmarCompraOXXO"){
                        tituloCorreo = "Pedido en proceso, datos para pago en OXXO"
                    var date = new Date(data.data.charges._json.data[0].payment_method.expires_at * 1000);
                    // Hours part from the timestamp
                    var hours = date.getHours();
                    // Minutes part from the timestamp
                    var minutes = "0" + date.getMinutes();
                    // Seconds part from the timestamp
                    var seconds = "0" + date.getSeconds();

                    // Will display time in 10:30:23 format
                    var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                    var contenido = ContenidoMail(global.Total, data.data.charges._json.data[0].payment_method.reference, this.state.FormaDePago == 0 ? 0 : 1, this.state.DatosCompra.Domicilio, data.data.charges._json.data[0].payment_method);
                    } else if(metodo == "CorfirmarTransferencia"){
                        tituloCorreo = "Pedido en proceso, datos para pago por transferencia"
                        var date = new Date(data.data.charges._json.data[0].payment_method.expires_at * 1000);
                        var hours = date.getHours();
                        var minutes = "0" + date.getMinutes();
                        var seconds = "0" + date.getSeconds();
                        var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                        var contenido = ContenidoMail(global.Total, data.data.charges._json.data[0].payment_method.reference, 2, this.state.DatosCompra.Domicilio, data.data.charges._json.data[0].payment_method);
                    }
                    else{
                        var contenido = ContenidoMail(global.Total,0, this.state.FormaDePago == 0 ? 0 : 1, this.state.DatosCompra.Domicilio, global.Total == 0 ? null :data.data.charges._json.data[0].payment_method );

                    }



                    // this.setState({
                    //     imagenCargo:data.data.charges._json.data[0].payment_method.reference
                    // });
                 


                    //    EnviarMail("arath890@gmail.com", 'compra exitosa', contenido);
                    this.setState({
                        VerModalCompra: false
                    });
                    EnviarMail(this.state.DatosCompra.customer_info.email, tituloCorreo, contenido);

                    localStorage.removeItem("ValoresParaCompra");
                    this.setState({
                        VerModalExito: true,
                    },() =>{
                        var NumeroCarrito = JSON.parse(Cookies.get("UsuarioTerco"));

                        NumeroCarrito.Carrito = 0;
                        Cookies.set('UsuarioTerco', JSON.stringify(NumeroCarrito));
                    });


                }
            });



    }
    ObtenerCompra() {


        fetch(Globales.Url + 'Compras/ConsultarCompra', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }, body: JSON.stringify({ _id: localStorage.getItem("idCompra") })
        })
            .then(res => res.json())
            .then(data => {
                if (data.error) { //Error al obtener los registros

                } else { //Obtuvo los registros correctamente
                    data.data.DatosCompra[0].obj1.map(m => {
                        m["PrecioTotal"] = m.Cantidad * m.idProducto.PrecioPublico;
                    })

                    this.setState({
                        ListaCarrito: data.data.DatosCompra[0].obj1,
                        cargando: false
                    }, () => {
                        this.CalcularTotales();
                    });

                }
            });
    }



    AgregarQuitarCantidadProducto(opcion) {

        if (opcion == 1) {
            this.setState({ NombreAtributo: this.state.NombreAtributo + 1 })
        } else {
            if ((this.state.NombreAtributo - 1) > 0) {
                this.setState({ NombreAtributo: this.state.NombreAtributo - 1 })

            } else {
                this.setState({ NombreAtributo: 0 })

            }
        }
    }
    AgregarPiezas(idProducto, Opcion) {
        this.state.ListaCarrito.map(m => {
            if (m._id == idProducto) {
                if (Opcion == true) {
                    m.Cantidad = m.Cantidad + 1;
                } else {
                    if (m.Cantidad != 0) {
                        m.Cantidad = m.Cantidad - 1;
                    }
                }
                /**CALCULAR TOTALES */
                m.PrecioTotal = m.Cantidad * m.idProducto.PrecioPublico;

            }
        })
        this.CalcularTotales()
        //  this.CalcularTotales();
        this.setState({ a: "a" })
    }
    CalcularTotales() {
        global.Subtotal = 0;
        global.Total = 0;
        global.IVA = 0;
        global.Descuento = 0;
        global.TotalProductos = 0;
        this.state.ListaCarrito.map(m => {
            if (m.idDiseno.Descuento == true) {
                global.Descuento = global.Descuento + m.Descuento;
                global.Subtotal = (global.Subtotal + ((m.idModelo.GruposMaterial[0].PrecioSinIva) * m.Cantidad))
        
              } else {
                global.Subtotal = (global.Subtotal + (m.idModelo.GruposMaterial[0].PrecioSinIva * m.Cantidad))
        
              }
        
        
        
              global.IVA = (global.IVA + (m.idModelo.GruposMaterial[0].valorIva * m.Cantidad));
              global.Total = global.Subtotal + global.IVA - global.Descuento - global.Saldo;
        
            })
            global.Total =  Number((global.Total + global.Envio).toFixed(2));

        this.setState({ a: "a" });

    }

    RecibirDatosCompra(datos, EventoTab, valido) {
        this.setState({
            FormaDePago: datos.FormaDePago,
            FormValido: valido
        }, () => {
            /**TARJETA */
            if (datos.FormaDePago == 0) {
                this.state.DatosCompra.customer_info.name = datos.NombreTitular;
                this.state.DatosTarjeta.NumeroTarjeta = datos.NumeroTarjeta;
                this.state.DatosTarjeta.NombreTitular = datos.NombreTitular;
                this.state.DatosTarjeta.Mes = datos.Mes;
                this.state.DatosTarjeta.Año = datos.Año;
                this.state.DatosTarjeta.CodigoSeguridad = datos.Codigo;


            }
            /**OXXO */
            if (datos.FormaDePago == 1) {
                //console.log(datos)
                this.state.DatosCompra.customer_info.name = datos.NombreCliente;
                this.state.DatosCompra.customer_info.email = datos.CorreoCliente;
                this.setState({
                    FormValido: valido,
                    CorreoCliente: datos.CorreoCliente

                },()=>{
                    //console.log(this.state.DatosCompra)
                });

            }
            if(datos.FormaDePago == 2){
                this.state.DatosCompra.customer_info.name = datos.NombreCompletoTransferencia;
                this.state.DatosCompra.customer_info.email = datos.CorreoElectronicoTransferencia;
                this.setState({
                    FormValido: valido,
                    CorreoCliente: datos.CorreoElectronicoTransferencia

                },()=>{
                    //console.log(this.state.DatosCompra)
                });
            }
            if (EventoTab == true) {
                document.getElementById("BtnComprar").focus()
            }
        });




    }

    ValidarCompra() {

        if (this.state.FormValido == true) {
            /**TARJETA */

            if (this.state.FormaDePago == 0 && this.state.FormValido == true) {
                this.setState({
                    VerModalCompra: true
                });
                this.ObtenerTokenTarjeta()

            }
            /**OXXXO */

            if (this.state.FormaDePago == 1) {
                this.setState({
                    VerModalCompra: true
                });
                this.state.DatosCompra.charges[0] = {
                    payment_method: {
                        type: "oxxo_cash",
                        expires_at:Number.parseInt((new Date(new Date(new Date().setDate(new Date().getDate() + 2))) / 1000).toFixed(0))
                    }
                };
                this.Comprar();
            }

            //Transferencia
            if(this.state.FormaDePago == 2){
                this.setState({
                    VerModalCompra: true
                });
                this.state.DatosCompra.charges[0] = {
                    payment_method: {
                        type: "spei",
                        expires_at:Number.parseInt((new Date(new Date(new Date().setDate(new Date().getDate() + 2))) / 1000).toFixed(0))
                    }
                };
                this.Comprar();
            }
        } else {
            if (this.state.FormaDePago != null) {
                this.showNotification("Todos los campos son requeridos", "warning")

            } else {
                this.showNotification("Seleccione una forma de pago", "warning")

            }

        }


    }

    CambiarFormatoPrecio(precio) {
        var num = '$' + parseFloat(precio, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
        return num;
    }
    showNotification(Mensaje, Color) {
        var x = [];
        this.setState({
            colorNotificacion: Color,
            mensaje: Mensaje,
            br: true
        });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false });
                this.setState(x);
            }.bind(this),
            3000
        );
    }

    render() {

        const { classes } = this.props;
        const styleTotal = {
            color: "#FFFFFF",
            textDecoration: "none",
            fontWeight: "700",
            marginTop: "30px",
            marginBottom: "25px",
            minHeight: "32px",
            fontFamily: `"Roboto Slab", "Times New Roman", serif`,
            marginTop: ".625rem",
            marginBottom: "0.75rem",
            minHeight: "auto"
        }

        const containerFluid = {
            paddingRight: "15px",
            paddingLeft: "15px",
            marginRight: "auto",
            marginLeft: "auto",
            width: "100%"
        };
        const container = {
            ...containerFluid,
            "@media (min-width: 576px)": {
                maxWidth: "540px"
            },
            "@media (min-width: 768px)": {
                maxWidth: "720px"
            },
            "@media (min-width: 992px)": {
                maxWidth: "960px"
            },
            "@media (min-width: 1200px)": {
                maxWidth: "1920px"
            }
        };
        return (
            <div>
                <Header
                    links={<HeaderLinks dropdownHoverColor="dark" />}
                    fixed
                    color="transparent"
                    changeColorOnScroll={{
                        height: 80,
                        color: "dark"
                    }}
                />

                <Parallax
                    image={require("assets/img/border.svg")}
                    // filter="dark"
                    style={{
                        height: "20vh",
                        overflow: "hidden"
                    }}
                >
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem
                                md={8}
                                sm={8}
                                className={classNames(
                                    classes.mlAuto,
                                    classes.mrAuto,
                                    classes.textCenter
                                )}
                            >

                            </GridItem>
                        </GridContainer>
                    </div>
                </Parallax>
                <Parallax
                    // filter="dark"
                    style={{
                        height: "10vh",
                        overflow: "hidden"
                    }}
                ></Parallax>
                {/* <div className={classNames(classes.main, classes.mainRaised)}> */}
                <div className={classes.container}>
                    <div>
                        <div>
                            <GridContainer justify="center">
                                <GridItem>
                                    <h2 className={classes.cardTitle}>Confirmar compra</h2>
                                </GridItem>



                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={7} md={7} lg={7}>
                                    <GridContainer>
                                        <FormasDePago RecibirDatosCompra={this.RecibirDatosCompra} />

                                    </GridContainer>
                                    <GridContainer>
                                        <img src={this.state.imagenCargo} />




                                    </GridContainer>

                                </GridItem>
                                <GridItem xs={12} sm={5} md={4} lg={4}>
                                    <Card >

                                        <CardBody>
                                            <GridContainer>

                                                <GridItem>
                                                    &nbsp;

                                            </GridItem>
                                            </GridContainer>
                                            <GridContainer>

                                                <GridItem>
                                                    <h4>Resumen de compra</h4>
                                                </GridItem>

                                                <GridItem>
                                                    <hr />
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer >

                                                <GridItem className={`${classes.textRight}`} xs={6} sm={5} md={5} lg={5}>
                                                    <span  >Productos ({global.TotalProductos}):  </span><br /><br />
                                                    {/* <span  >Subtotal: </span><br /> */}
                                                    <span >IVA: </span><br /><br />
                                                    <span >Envío: </span><br /><br />
                                                    <span  >Descuento: </span> <br /><br />
                                                    <span>Saldo: </span><br/><br/><br />
                                                    <span  >Total: </span>

                                                </GridItem>

                                                <GridItem className={`${classes.textRight}`} xs={4} sm={5} md={4} lg={5}>
                                                    {/* <span  >  {global.TotalProductos} </span><br /> */}
                                                    <span  >{this.CambiarFormatoPrecio(global.Subtotal)}</span><br /><br />
                                                    <span >{this.CambiarFormatoPrecio(global.IVA)}</span><br /><br />
                                                    <span >{this.CambiarFormatoPrecio(global.Envio)}</span><br /><br />
                                                    <span  >{this.CambiarFormatoPrecio(global.Descuento)}</span> <br /><br />
                                                    <span  >{this.CambiarFormatoPrecio(global.Saldo)}</span> <br /><br /><br />
                                                    <span  >{this.CambiarFormatoPrecio(global.Total)}</span>
                                                    <br />
                                                    <br />
                                                    <br />

                                                </GridItem>


                                            </GridContainer>
                                            <GridContainer>


                                                <GridItem className={`${classes.textCenter}`} lg={12}>
                                                    <Button id="BtnComprar" onClick={() => { this.ValidarCompra() }} size="md" color="github">COMPRAR</Button>

                                                </GridItem>


                                            </GridContainer>
                                        </CardBody>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </Card>


                                </GridItem>


                            </GridContainer>
                            <ModalCargando Cuerpo={"Confirmando compra"} Ver={this.state.VerModalCompra} />
                            <ModalExito VerModal={this.state.VerModalExito} FormaDePago={this.state.FormaDePago} Correo={this.state.CorreoCliente} />
                            <Snackbar
                                place="tc"
                                color={this.state.colorNotificacion}
                                message={this.state.mensaje}
                                open={this.state.br}
                                closeNotification={() => this.setState({ br: false })}
                                close
                            />


                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default withStyles(shoppingCartStyle)(ShoppingCartPage);
