import Cookies from 'js-cookie'

const productivo =  true; 
//Key publicas
const keyProductivaConekta = "key_fQS3zDL1R4ErGgew9sV5Y1Q";
const keyTestConekta = "key_I64zYkshmrERtpUHqkqm6Am";

const Globales = {

 Url: process.env.NODE_ENV === 'production' ?
 //'http://3.95.246.221:5001/'
 
 'https://ws.fabricanook.com/'
 
: 'http://localhost:5001/',

 token: 'Bearer ' + Cookies.get('token'),
//18.204.136.170
  usuario: localStorage.getItem("UsuarioTerco"),
  //Produccion
  //KeyConekta:"key_fQS3zDL1R4ErGgew9sV5Y1Q",
  //Pruebas
  //KeyConekta:"key_I64zYkshmrERtpUHqkqm6Am", //Key publica
  //KeyConekta: process.env.NODE_ENV === 'production' ? 'key_fQS3zDL1R4ErGgew9sV5Y1Q' : 'key_I64zYkshmrERtpUHqkqm6Am'
  KeyConekta: (productivo)?keyProductivaConekta:keyTestConekta,
}
if(Cookies.get("UsuarioTerco") == undefined){

  var sesion = {
    id: '',
    Carrito: "",
    ListaCarrito: [],
    ListaDeseos: [],
    Usuario: "",
    Nombre: "",
    Usuario: "",
    Correo: "",
    Telefono: "",
    TipoUsuario: "",
    token: "",
    IndicadorTerco: "",
  }
  Cookies.set("UsuarioTerco", JSON.stringify(sesion))
}else{

}
export default Globales;

// ObjectId("5d2786d4c3184c2aa0d3b42c")