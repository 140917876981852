/* eslint-disable */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
// core components
import Header from "../Generales/Header/Header.jsx";
import HeaderLinks from "../Generales/Header/HeaderLinks.jsx";
import Table from "components/Table/Table.jsx";
import Pagination from "@material-ui/lab/Pagination";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Badge from "components/Badge/Badge.jsx";
import Muted from "components/Typography/Muted.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "components/Snackbar/Snackbar.jsx";



import UsuarioBlanco from "assets/img/faces/UsuarioBlanco1.png";
import avatar from "assets/img/faces/avatar.jpg";
import Camisa from "../../../node_modules/@icons/material/TshirtCrewIcon"
import CamisaImg from "../../assets/img/terco-img/deseos/MustangBadboy.jpg";




import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.jsx";

import Cookies from 'js-cookie'
import {
  Edit, ShoppingBasket, Favorite, AccountCircle, CardMembership, Payment, AttachMoney, StoreMallDirectory,
  Create, Gesture, MoreVert, Delete, Save, Cancel, Close, ColorLens, Grade, FormatBold, Add, Mail, LaptopWindowsRounded
} from "@material-ui/icons";
import ModalEditar from './ModalEditar';
import ModalCancelar from './ModalCancelarPedido';
import ModalCancelarMembresia from '../Generales/Modales/ModalAccion';
import DetalleVenta from './ModalDetallesCompra';
import TiendaArtista from './TiendaArtista';
import ModalAgregarTienda from './ModalAgregarTienda';
import ModalCargando from '../Generales/Modales/ModalCargando';
import Loader from 'react-loader-spinner';
import ModalMembresia from './ModalExitoMembresia';
import ModalCorreoOxxo from './ModalCorreoOxxo'



import Globales from "utils/Globales";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import Enumerable from "linq"
import Conekta from "conekta-card-tokenizer";


import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from "antd/lib/card/Grid";

const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

const clasesTool = useStyles;




const conektaHelper = {
  initConekta: () => {
    window.Conekta.setPublicKey(Globales.KeyConekta)
  },
  getCardBrand: (cardNumber) => {
    return window.Conekta.card.getBrand(cardNumber)
  },
  validateCardNumber: (cardNumber) => {
    return window.Conekta.card.validateNumber(cardNumber)
  },
  validateCvc: (cvc) => {
    return window.Conekta.card.validateCVC(cvc)
  },
  validateExpirationDate: (expiryMonth, expiryYear) => {
    return window.Conekta.card.validateExpirationDate(expiryMonth, `20${expiryYear}`)
  },
  tokenize: (cardNumber, cardHolder, expiryMonth, expiryYear, cvc, successCallback, errorCallback) => {
    const tokenParams = {
      card: {
        number: cardNumber,
        name: cardHolder,
        exp_year: expiryYear,
        exp_month: expiryMonth,
        cvc


      }
    }
    window.Conekta.Token.create(tokenParams, successCallback, errorCallback)
  }
}


// core components

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BajaMembresia: false,
      DatosMembresia: {},
      EsRedSocial: true,
      CargandoVentas: false,
      CargandoCompras: false,
      IndicadorTerco: false,
      DisenosGrupo: [],
      VerAgregarTienda: false,
      PrecioMembresia: 0,
      PrecioMembresiaDescuento: 0,
      DescuentoMembresia: 0,
      idPedidoCancelacion: "",
      VerTabs: false,
      Membresia: null,
      IndexTabCliente: 1,
      IndexTabArtista: 2,
      //TIENDA
      VerTienda: true,
      //COMPRAS
      DetallesVenta: {},
      VerModalCancelar: false,
      FechaParaCancelar: "",
      //Diseños
      Disenos: [],
      //Ventas
      Ventas: [],
      //PERFIL
      NumeroTarjetaComision: '',
      BancoComision: '',
      NombreEmpresa: "",
      Saldo: 0,
      Portada: '',
      FotoPerfil: '',
      Logo: '',
      Usuario: JSON.parse(Cookies.get("UsuarioTerco")),
      VerDetalleCompra: false,
      Compras: [],
      TituloModal: '',
      show: false,
      id: '',
      Nombre: '',
      Correo: '',
      ConfirmacionContrasena: '',
      TipoUsuario: '',
      TipoUsuarioActual: '',
      CuentaBancaria: '',
      Telefono: '',
      Usuario: '',
      ContraseniaActual : '',
      ContrasenaNueva: '',
      mensaje: '',
      ImagenPortada: [],
      ListaPortada: [],
      ImagenSubida: '',
      VerBotonFotoPerfil: false,
      br: false,
      FechaVencimientoMembresia: "",
      /**Ver */
      EsVenta: false,
      VerModalMembresia: false,
      VerTiendaArtista: false,
      CuerpoCargando: "",
      VerCargando: "",
      SeleccionDisenos: true,
      SeleccionFavoritos: false,
      VerModalCancelarMembresia: false,
      VerModalCorreo: false,
      ImporteComisionesPendientes: 0,
      /**DATOS PAGO */
      NumeroTarjeta: "",
      NombreTitular: "",
      CodigoSeguridad: "",
      FechaExpiracion: "",
      Mes: "",
      Año: "",

      NombreCliente: "",
      CorreoCliente: "",
      error: {
        NumeroTarjeta: false,
        NombreTitular: false,
        CodigoSeguridad: false,
        FechaExpiracion: false,
        Mes: false,
        Año: false,
        NombreCliente: false,
        CorreoCliente: false
      },
      /**ERRORES */
      colorNotificacion: 'success',
      errorNombre: false,
      errorCorreo: false,
      errorTelefono: false,
      errorCuentaBancaria: false,
      errorUsuarioLogin: false,
      errorContrasenaAnteriorLogin: false,
      errorUsuario: false,
      errorContrasenaAnterior: false,
      errorConfirmacionContrasena: false,
      errorTipoUsuario: false,
      errorNumeroTarjetaComision: false,
      errorBancoComision: false,
      isRequireDatosTarjetaMembresia : false,
      cargandoDisenios : true,
      currentPage: 1,
      itemsPerPage: 12,
      totalProductos: 0,    
    };
    /**BIND */
    {
      global.Fin = 0;
      global.UrlTemporal = window.location.href;
      this.eventoModalCancelarMembresia = this.eventoModalCancelarMembresia.bind(this);
      this.eventoModal = this.eventoModal.bind(this);
      this.VerDetalleCompra = this.VerDetalleCompra.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.CambiarLogo = this.CambiarLogo.bind(this);
      this.EventoModalCancelar = this.EventoModalCancelar.bind(this);
      this.EventoEliminarTienda = this.EventoEliminarTienda.bind(this);
      this.VolverDiseñar = this.VolverDiseñar.bind(this);
      this.EventoModalAgregarTienda = this.EventoModalAgregarTienda.bind(this);
      this.handleChangeTarjeta = this.handleChangeTarjeta.bind(this);
      this.successCallback = this.successCallback.bind(this);
      this.errorCallback = this.errorCallback.bind(this);
      this.EventoModalCorreo = this.EventoModalCorreo.bind(this);
      this.handlePageChange = this.handlePageChange.bind(this);

    }

  }
  componentDidUpdate() {
    // if (global.UrlTemporal == window.location.href) {
    // } else {
    //   global.UrlTemporal = window.location.href;
    //   this.componentDidMount();
    // }
  }

  handlePageChange(event, newPage) {
    var idCliente = JSON.parse(Cookies.get("UsuarioTerco")).id;
    this.setState({ currentPage: newPage, cargandoDisenios: true }, () => {
      this.ObtenerDisenosCliente(idCliente, newPage);
    });
  }
  obtenerEmpresa(op) {
    fetch(Globales.Url + 'Empresa/Empresa', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) {
        } else {
          if (op == 0) {
            this.setState({
              PrecioMembresia: data.data[0].PrecioMembresia
            })
            this.ObtenerMembresias()
          } else {
            this.setState({
              NombreEmpresa: data.data[0].NombreCortoEmpresa
            })
          }


        }
      });
  }


  ObtenerMembresias() {
    fetch(Globales.Url + 'ObtenerMembresiaVigente', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {

        } else {
          if (data.data != null) {
            this.setState({
              DescuentoMembresia: data.data.PorcentajeDescuento,
              PrecioMembresiaDescuento: this.state.PrecioMembresia - (this.state.PrecioMembresia * (data.data.PorcentajeDescuento / 100)),
              FechaVencimientoMembresia: data.data.FechaFinal
            })
            //console.log("este es el precio de lamembresia")
            //console.log(this.state.PrecioMembresiaDescuento)
            this.setState({
              isRequireDatosTarjetaMembresia: (this.state.PrecioMembresiaDescuento == 0)?false:true,
            })
            //console.log(this.state.isRequireDatosTarjetaMembresia)

          } else {

          }
        }
      })

  }

  componentDidMount() {
    //console.log("1111")
    const script = document.createElement('script')
    script.src = 'https://cdn.conekta.io/js/latest/conekta.js'
    script.async = true
    document.body.appendChild(script)
    conektaHelper.initConekta();

    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    //  var _id = "5dd57d039d4bb62c84e77d16";
    //  window.location.href = `#/Tienda/${_id}`;

    // "/Clientes/Editar/:id",
    var idCliente = "";
    if (this.props.match.params.id !== undefined) {
      //console.log("no fue ", this.props.match.params)
      this.setState({
        VerTiendaArtista: true,
        id: this.props.match.params.id
      });
      idCliente = this.props.match.params.id
    } else {
      //console.log("si fue ")
      idCliente = JSON.parse(Cookies.get("UsuarioTerco")).id;
      this.setState({
        VerTiendaArtista: false,
        id: JSON.parse(Cookies.get("UsuarioTerco")).id
      });
      this.obtenerEmpresa(1);
      this.ObtenerCompras(idCliente);
      this.ObtenerDisenosCliente(idCliente,this.state.currentPage);
    }
  }

  componentWillMount() {
    //console.log("2222")
    if (JSON.parse(Cookies.get("UsuarioTerco")).TipoUsuario == "Cliente") {
      this.setState({ TipoUsuario: "Cliente" })
    }
    if (JSON.parse(Cookies.get("UsuarioTerco")).TipoUsuario == "Artista") {
      this.setState({ TipoUsuario: "Artista" })

    }
    var idCliente = "";
    if (this.props.match.params.id !== undefined) {
      this.setState({
        VerTiendaArtista: true,
        id: this.props.match.params.id
      });
      idCliente = this.props.match.params.id
      this.ObtenerInformacionCliente(idCliente);
    }
    else {
      this.ObtenerInformacionCliente(JSON.parse(Cookies.get("UsuarioTerco")).id);
    }
  }

  /**PETICIONES A API */

  ObtenerInformacionCliente(id) {
    fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/ObtenerInformacionCliente', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: id })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {

        } else {
            ////console.log("informacion del usuario.-.--------->", data.data)
            if (data.data.Membresia == false || data.data.Membresia == undefined || this.state.VerTiendaArtista) {
              this.setState({
                Membresia: false
              })
              this.obtenerEmpresa(0)
            } else {
              if(data.data.idUsuarioConekta == undefined || data.data.idUsuarioConekta == null){

              }else{
                this.ObtenerInformacionMembresia(data.data.idUsuarioConekta);
              }
              ////console.log("sdffffffsdfsd-sdf-ds-fds-fdf", this.state.VerTiendaArtista)
              if(!this.state.VerTiendaArtista){
              this.ObtenerVentasArtista();
              this.ObtenerComisionPorPagarArtista();
              }
            }
        
          this.setState({
            BancoComision: data.data.BancoComision,
            NumeroTarjetaComision: data.data.NumeroTarjetaComision,
            EsRedSocial: data.data.idTokenSocialNetwork != undefined ? true : false,
            IndicadorTerco: data.data.IndicadorTerco,
            Saldo: data.data.Saldo,
            id: data.data._id,
            TipoUsuario: data.data.TipoUsuario,
            Nombre: data.data.Nombre,
            Correo: data.data.Correo,
            ContraseniaActual: data.data.Contrasena,
            Telefono: data.data.Telefono,
            Usuario: data.data.Usuario,
            Membresia: data.data.Membresia,
            IndexTabArtista: data.data.Membresia == false ? 2 : 0,
            IndexTabCliente: 3,
            Portada: data.data.ImagenPortada == null ? null : "data:image/png;base64," + data.data.ImagenPortada, //Globales.Url + data.data.ImagenPortada,
            FotoPerfil: data.data.FotoPerfil == null ? null : "data:image/png;base64," + data.data.FotoPerfil, //Globales.Url + data.data.FotoPerfil,
            Logo: data.data.LogoTienda == null ? null : "data:image/png;base64," + data.data.LogoTienda//Globales.Url + data.data.LogoTienda,

          }, () => {
            if (localStorage.getItem("VerCompras") != undefined) {
              if (data.data.TipoUsuario == "Cliente") {
                this.state.IndexTabCliente = 1;
              } else {
                this.state.IndexTabArtista = 1;
              }
              localStorage.removeItem("VerCompras")
            }
            this.setState({
              VerTabs: true
            });
          })

        }
      })

  }

  ObtenerImagenes(id) {
    fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/ObtenerImagenesTiendaArtista', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: id })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {

        } else {

          this.state.RutaPortada = data.data.ImagenPortada;
          this.state.RutaFotoPerfil = data.data.FotoPerfil;
          this.state.RutaLogo = data.data.LogoTienda;
          this.editarPerfil();

        }
      })

  }

  ObtenerCompras() {
    global.Fin += 10;
    this.setState({
      CargandoCompras: true
    });
    fetch(Globales.Url + 'Compras/ObtenerComprasPorClientes', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({ idCliente: JSON.parse(Cookies.get("UsuarioTerco")).id, Fin: global.Fin })
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros

        } else { //Obtuvo los registros correctamente


          if (data.data.length < global.Fin) {
            if (data.data.length > 0) {
              if (global.Fin != 10) {
                this.showNotification("No se encontraron más compras", 'warning');
              }
            }

          }
          //console.log("compras--->", data.data)
          data.data.map((m, index) => {

            if (m.responseFromConekta == undefined) {
              m["responseFromConekta"] = { _json: { amount: 0 } }
            }

            if (m.Estatus == 0) {
              m.NombreEstatusPedido = "Cancelado"
            }

            //compra.responseFromConekta._json.amount

          })
          this.setState({
            Compras: data.data,
            CargandoCompras: false

          });
        }
      });


  }
  validarEmail(correo) {
    if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(correo)) {
      return true;
    } else {
      return false
    }
  }

  validarNumeroTelefono(numero) {
    return /^\d{10}$/.test(numero)
  }

  ObtenerVentasArtista() {
    this.setState({
      CargandoVentas: true
    });
    fetch(Globales.Url + 'Compras/ObtenerVentasPorArtista', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({ idCliente: JSON.parse(Cookies.get("UsuarioTerco")).id })
    })
      .then(res => res.json())
      .then(data => {
        //console.log("🍺 / file: Perfil.jsx / line 571 / data", data)
        if (data.error) { //Error al obtener los registros

        }
        else { //Obtuvo los registros correctamente
          //console.log("las ventas----", data)
          if (data.data != null) {
            if (data.data.length < global.Fin) {
              if (data.data.length > 0) {
                if (global.Fin != 10) {
                  this.showNotification("No se encontraron más compras", 'warning');
                }
              }
            }
            var totalComisionesPendientes = 0;
            data.data.map((m, index) => {
              if (m.responseFromConekta == undefined) {
                m["responseFromConekta"] = { _json: { amount: 0 } }
              }
              if (m.Estatus == 0) {
                m.NombreEstatusPedido = "Cancelado"
              }
            })
            this.setState({
              Ventas: data.data,
              CargandoVentas: false
            });
          }
          else{
            //console.log("si llego aqui w www")
            this.setState({              
              CargandoVentas: false
            });
          }
        }
      });
  }

  ObtenerComisionPorPagarArtista() {
    fetch(Globales.Url + 'Compras/ObtenerComisionTotalPorArtista', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({ idCliente: JSON.parse(Cookies.get("UsuarioTerco")).id })
    })
      .then(res => res.json())
      .then(data => {
        //console.log("🍺 / file: Perfil.jsx / line 637 / data", data)
        if (data.error) { //Error al obtener los registros

        }
        else { //Obtuvo la comision correctamente                   
          this.setState({
            ImporteComisionesPendientes: data.data
          });
        }
      });
  }

  editarPerfil() {
    if (this.state.Nombre == "" || this.validarNumeroTelefono(this.state.Telefono) == false) {
      this.setState({
        errorNombre: this.state.Nombre == "" ? true : false,
        errorTelefono: this.validarNumeroTelefono(this.state.Telefono) == false ? true : false
      }, () => {
        this.showNotification(
          this.validarEmail(this.state.Correo) ? "Favor de completar los campos obligatorios" :
            !this.validarNumeroTelefono(this.state.Telefono) ? "Favor de ingresar número de teléfono a 10 digitos" :
              "Favor de ingresar los campos obligatorios",
          'danger')
      })
    }
    else {
      if (this.state.TipoUsuarioActual != "") {
        this.state.TipoUsuario == this.state.TipoUsuarioActual
      }
      if (this.state.ContrasenaNueva === "" && this.state.ConfirmacionContrasena === "") {
        fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/editarPerfil', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
            //console.log(this.state)
            if (data.error) {
              this.showNotification(data.mensaje, 'danger');
            } else {
              Cookies.getJSON('Nombre', this.state.Nombre);
              Cookies.getJSON('Correo', this.state.Correo);
              Cookies.getJSON('Telefono', this.state.Telefono);
              Cookies.getJSON('TipoUsuario', this.state.TipoUsuario);
              this.setState({ errorCorreo: false });
              this.showNotification(data.mensaje, 'success');
              this.props.eventClick(false, this.state.ImagenSubida);
            }
          })
          .catch(err => console.error(err));
        //Guardar perfil
        // this.setState({ colorNotificacion: 'success', mensaje: 'Se actualizo la contraseña correctamente', errorContrasenaAnterior: false, errorContrasenaNueva: false, errorConfirmacionContrasena: false })
        //  this.showNotification();
      }
      else { //Cambio de contraseña
        var valor2 = 0;
        var valor3 = 0;
        if (this.state.ContrasenaNueva === '') {
          this.setState({ colorNotificacion: 'danger', mensaje: 'Complete los campos para actualizar la contraseña', errorContrasenaNueva: true });
          this.showNotification();
          valor2 = 1;
        } else {
          this.setState({ errorContrasenaNueva: false })
          valor2 = 0;
        }
        if (this.state.ConfirmacionContrasena === '') {
          this.setState({ colorNotificacion: 'danger', mensaje: 'Complete los campos para actualizar la contraseña', errorConfirmacionContrasena: true });
          this.showNotification();
          valor3 = 1;
        } else {
          this.setState({ errorConfirmacionContrasena: false })
          valor3 = 0;
        }
        if (valor2 + valor3 === 0) {
          fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/editarPerfil', {
            method: 'POST',
            headers: {
              Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify(this.state)
          }
          ).then(res => res.json())
            .then(data => {
              if (data.error) {
                this.showNotification(data.mensaje, 'danger');
              }
              else {
                Cookies.getJSON('Nombre', this.state.Nombre);
                Cookies.getJSON('Correo', this.state.Correo);
                Cookies.getJSON('Telefono', this.state.Telefono);
                Cookies.getJSON('TipoUsuario', this.state.TipoUsuario);
                this.setState({ errorTelefono: false, errorCorreo: false, ContrasenaNueva: '', ConfirmacionContrasena: '', errorNuevaContrasena: false, errorNuevaContrasena: false, errorConfirmacionContrasena: false });
                this.showNotification(data.mensaje, 'success');
                this.props.eventClick(false, "");
              }
            })
            .catch(err => console.error(err));
        }
      }
    }

  }

  ActualizarTarjetaComisiones() {
    var valido = true;

    if (this.state.BancoComision == '') {
      valido = false;
      this.setState({ errorBancoComision: true })
    } else {
      this.setState({ errorBancoComision: false })

    }

    if (this.state.NumeroTarjetaComision == '') {
      valido = false;

      this.setState({ errorNumeroTarjetaComision: true })

    } else {
      this.setState({ errorNumeroTarjetaComision: false })

    }

    if (valido == true) {
      fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/ActualizarTarjetaComision', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }, body: JSON.stringify({ idCliente: JSON.parse(Cookies.get("UsuarioTerco")).id, Tarjeta: this.state.NumeroTarjetaComision, Banco: this.state.BancoComision })
      })
        .then(res => res.json())
        .then(data => {

          if (data.error) { //Error al obtener los registros
            this.showNotification("Ocurrió un error al actualizar la información", "danger")

          } else { //Obtuvo los registros correctamente
            this.showNotification("Información Actualizada", "success")

          }

        });
    } else {

    }




  }

  ObtenerDisenosCliente(id,pagina) {
    this.setState({
      cargandoDisenios : true

    })
    const porPagina = this.state.itemsPerPage;
    fetch(Globales.Url + 'DisenoProductos/DisenoProductos/ListarDisenosPorClientePerfil', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idCliente: id,pagina: pagina, itemPorPagina: porPagina })
    }
    ).then(res => res.json())
      .then(Disenos => {
        if (Disenos.error) {

        } else {
          var disenosMostrar = []
          Disenos.data.map(m => {
            if (m.idDisenoPadre == null) {
              disenosMostrar.push(m)
            }
          })
          this.setState({
            Disenos: disenosMostrar
          });
          this.setState({
            cargandoDisenios : false
      
          })
          this.setState({ totalProductos: Disenos.totalRegistros });
        }
      })
  }

  EliminarDisenoGuardado(id) {
    fetch(Globales.Url + 'DisenoProductos/DisenoProductos/EliminarDiseno', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idDiseno: id })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {

        } else {
          this.ObtenerDisenosCliente(this.state.id,this.state.currentPage)

        }
      })
  }


  AgregarDisenoATienda(Diseno) {
    this.setState({
      VerTienda: false,
      CuerpoCargando: "Agregando a tienda",
      VerCargando: true
    });

    fetch(Globales.Url + 'DisenoProductos/DisenoProductos/AgregarYCrearDisenosTienda', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idDiseno: Diseno._id })
    }
    ).then(res => res.json())
      .then(Disenos => {
        if (Disenos.error) {
          this.showNotification("Ocurrio un error", "danger")
        } else {
          this.setState({
            VerTienda: true,
            VerCargando: false,
            CuerpoCargando: "",
          });
          this.ObtenerDisenosCliente(this.state.id,this.state.currentPage)
          this.showNotification("Producto agregado a tienda", "success")

        }
      })


    /**mas viejo */
    // fetch(Globales.Url + 'DisenoProductos/DisenoProductos/AgregarDisenoATienda', {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
    //   }
    //   , body: JSON.stringify({ idDiseno: Diseno._id, Diseno: Diseno })
    // }
    // ).then(res => res.json())
    //   .then(Disenos => {
    //     if (Disenos.error) {
    //       this.showNotification("Ocurrio un error", "danger")
    //     } else {
    //       this.setState({
    //         VerTienda: true,
    //         VerCargando: false,
    //         CuerpoCargando: "",
    //       });
    //       this.ObtenerDisenosCliente(this.state.id)
    //       this.showNotification("Producto agregado a tienda", "success")

    //     }
    //   })


    /**viejo */

    //  let regex = new RegExp(Diseno.nombre, 'gi');
    //  let aux = Enumerable.from(this.state.Disenos).where(function (x) { return x.nombre.match(regex) }).toArray();
    // this.setState({
    //   DisenosGrupo:aux,
    //   VerAgregarTienda:true
    // });
  }
  EliminarDisenoTienda(id) {
    this.setState({
      VerTienda: false
    });
    fetch(Globales.Url + 'DisenoProductos/DisenoProductos/EliminarDisenoTienda', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idDiseno: id })
    }
    ).then(res => res.json())
      .then(Disenos => {
        if (Disenos.error) {
          this.showNotification("Ocurrio un error", "danger")

        } else {
          this.setState({
            VerTienda: true
          });
          this.showNotification("Producto eliminado", "success")

        }
      })
  }

  EliminarFavorito(id) {
    this.setState({
      VerTienda: false
    });
    fetch(Globales.Url + 'Favoritos/EliminarDisenoFavoritos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idDiseno: id, idCliente: this.state.id })
    }
    ).then(res => res.json())
      .then(Disenos => {
        if (Disenos.error) {
          this.showNotification("Ocurrio un error", "danger")

        } else {
          this.setState({
            VerTienda: true
          });
          this.showNotification("Producto eliminado", "success")
          if(!this.state.VerTiendaArtista){
            this.ObtenerProductosDeseos();
            }
        }
      })
  }


  ObtenerProductosDeseos() {

    fetch(Globales.Url + 'ListaDeseos/ListaDeseos/ObtenerProductosListaDeseos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({ idCliente: JSON.parse(Cookies.get('UsuarioTerco')).id })
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
        } else { //Obtuvo los registros correctamente
          /**verificar rango de precios */
          var listaProductos = [];
          //console.log("la lista-------->",data.data)
          data.data.map(m => {
            listaProductos.push(m.diseñoProducto)
          })
          this.setState({
            Disenos: listaProductos
          })

        }
      });
  }

  ObtenerInformacionMembresia(id) {
    fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/ObtenerDatosTarjetaSuscripcion', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({ idUsuarioConekta: id })
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
        } else { //Obtuvo los registros correctamente
          //console.log("Datos de la membresia")
          //console.log(data.data)
          this.setState({
            DatosMembresia: data.data,
          })


        }
      });
  }

  /**EVENTOS MODAL */

  EventoModalAgregarTienda(e) {
    this.setState({
      VerAgregarTienda: false
    });
  }

  openModal = () => {
    this.setState({ show: true });
  }

  eventoModal(e, imagen) {
    if (!e) {
      //this.ObtenerImagenes(this.state.id)
      if (this.state.TituloModal == "Editar portada") {

        this.setState({
          Portada: imagen
        });
      }
      if (this.state.TituloModal == "Editar foto de perfil") {
        //console.log("la iamgen---->", imagen)

        this.setState({
          FotoPerfil: imagen
        });
      }
      if (this.state.TituloModal == "Editar logo") {
        this.setState({
          Logo: imagen
        });
      }

      this.setState({ show: false });
      //  this.ObtenerImagenes(this.state.id);


      return;
    }
    this.setState({ show: false })
    // this.cerrarSesion(this.seleccionado);
  }

  eventoClose = (mensaje, colorNotificacion) => {
    this.setState({ show: false });

    if (mensaje !== 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: colorNotificacion })
      this.showNotification();
    }
  }

  eventoModalCancelarMembresia(e) {
    if (e == true) {
      this.setState({ VerModalCancelarMembresia: false })
      this.BajaMembresia();
    } else {
      this.setState({ VerModalCancelarMembresia: false })
    }

  }

  EventoModalCorreo() {
    this.setState({
      VerModalCorreo: false
    })
  }

  CambiarLogo() {
    this.setState({
      show: true,
      TituloModal: "Editar logo"
    });
  }


  /**COMPRAS */
  VerDetalleCompra(Detalles, Tipo) {
    //console.log("🍺 / file: Perfil.jsx / line 1040 / Tipo", Tipo)
    if (Tipo == 0) {
      this.setState({ EsVenta: false })
    }

    if (Tipo == 1) {
      this.setState({ EsVenta: true })
    }
    this.setState({

      DetallesCompra: Detalles,
      VerDetalleCompra: !this.state.VerDetalleCompra
    });
  }
  CambiarFormatoPrecio(precio) {
    var num = '$' + parseFloat((precio / 100), 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
    return num;
  }


  /**EVENTOS ONCHANGE */

  handleChange(e) {
    const { name, value } = e.target;
    //console.log("------", value)
    if (name == "NumeroTarjetaComision") {
      if (value != '') {
        if (value.length < 17) {
          if (!/^[0-9]+$/.test(value)) {

          } else {
            this.setState({ [name]: value })

          }
        } else {
          return;
        }

      } else {
        this.setState({ [name]: value })

      }

    } else if (name == "Telefono") {

      if (value.length < 11 && /^[0-9]+$/.test(value) || value == "") {
        this.setState({
          [name]: value
        });
      }
    } else {
      this.setState({
        [name]: value
      });
    }
  }

  /**NOTIFICACIONES */
  showNotification(mensaje, color) {
    var x = [];
    this.setState({ br: true, mensaje: mensaje, colorNotificacion: color });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      3000
    );
  }

  /**OTRAS */
  CambiarFormatoFecha(Fecha) {
    var FechaMoment = moment(Fecha);
    FechaMoment.tz('America/Mazatlan').format('ha z');


    var Fecha = new Date(FechaMoment._i)
    var Fecha2 = Fecha.toLocaleDateString('en-GB');
    return Fecha2;
  }



  CancelarPedido(Fecha, FechaDeCompra, idCompra) {
    // var FechaMoment = moment(Fecha);
    // FechaMoment.tz('America/Mazatlan').format('ha z');
    // var fecha = (FechaMoment._a[2]).toString() + "/" + (FechaMoment._a[1] + 1).toString() + "/" + (FechaMoment._a[0]).toString();
    // var hora = (FechaMoment._a[3]).toString() + ":" + (FechaMoment._a[4]).toString();

    this.setState({
      // FechaParaCancelar: fecha + " " + hora,
      VerModalCancelar: true,
      idPedidoCancelacion: idCompra
    });
    //  this.ObtenerCompras();
  }
  EventoModalCancelar(e) {

    if (e == true) {
      this.showNotification("Tu pedido ha sido cancelado", "success")
      global.Fin = 0;
      this.ObtenerCompras();
    }
    this.setState({
      VerModalCancelar: false
    });
  }

  EventoEliminarTienda(e) {

    this.ObtenerDisenosCliente(this.state.id,this.state.currentPage)
    if (e == true) {
      this.showNotification("Producto eliminado de tienda", "success")

    } else {
      this.showNotification("Ocurrio un error", "danger")

    }


  }

  CambiarDisenosFavoritos(Opcion) {
    if (Opcion == 1) {
      this.ObtenerDisenosCliente(this.state.id,this.state.currentPage)
      this.setState({
        SeleccionDisenos: true,
        SeleccionFavoritos: false,
      });
    }
    if (Opcion == 2) {
      if(!this.state.VerTiendaArtista){
      this.ObtenerProductosDeseos();
      }

      this.setState({
        SeleccionDisenos: false,
        SeleccionFavoritos: true,
      });
    }
  }

  VolverDiseñar(disenos) {
    localStorage.setItem("idModelo", disenos._id)
    localStorage.setItem("idTalla", disenos.idTalla)
    localStorage.setItem("disenioSeleccionado", JSON.stringify(disenos))
    localStorage.setItem("nombreSeleccionado", disenos.nombre)
    localStorage.setItem("tipo", 2)
    localStorage.setItem("desdePerfil","true")
    localStorage.setItem("idModeloSeleccionado",disenos.idModelo)
    window.location.href = "#/diseñador3D"

  }

  /**COMPRA */
  handleChangeTarjeta(e) {
    const { name, value } = e.target;
    if (name == "NumeroTarjeta" || name == "CodigoSeguridad") {
      if (value != "") {
        if (!/^[0-9]+$/.test(value)) {
          // alert("Please only enter numeric characters only for your Age! (Allowed input:0-9)")
        } else {
          if (name == "NumeroTarjeta") {
            {
              if (value.length < 17) {
                this.setState({ [name]: value });

              }
            }

          }
          if (name == "CodigoSeguridad") {
            {
              if (value.length < 4) {
                this.setState({ [name]: value });

              }
            }

          }
        }
      } else {
        this.setState({ [name]: value });
      }

    }
    if (name == "Mes") {
      if (value == "") {
        this.setState({ [name]: value });

      } else {
        if (!/^[0-9]+$/.test(value)) {

        } else {
          if (parseInt(value) < 13) {
            this.setState({ [name]: value });
          }
        }
      }


    }
    if (name == "Año") {
      if (value == "") {
        this.setState({ [name]: value });
      } else {
        if (!/^[0-9]+$/.test(value)) {
          this.setState({ [name]: value });

        } else {
          if (parseInt(value) > 0 && parseInt(value) < 100) {
            this.setState({ [name]: value });
          }
        }
      }


    }
    if (name == "NombreTitular") {
      // Solo permitir letras
      const regexLetters = /^[A-Za-z\u00C0-\u017F\s]+$/;
      const isValid = regexLetters.test(value);
      if(isValid || value === ""){
        this.setState({ [name]: value });
      }
    }
  }

  ValidarDatos(FormaPago, Campo) {
    var err = this.state.error;
    var EventoTab = false;
    //  var valido = true;
    /**TARJETA */
    if (FormaPago == 0) {

      if (Campo == 1) {
        err.NumeroTarjeta = false;
        if (this.state.NumeroTarjeta == "" || this.state.NumeroTarjeta.length < 16) {
          err.NumeroTarjeta = true;
          //        valido=false;
        }
      }
      if (Campo == 2) {
        err.NombreTitular = false;
        if (this.state.NombreTitular == "") {
          err.NombreTitular = true;
          //valido=false;

        }
      }
      if (Campo == 3) {

        err.Mes = false;
        if (this.state.Mes.length < 2) {
          err.Mes = true;
          //      valido=false;

        }

      }

      if (Campo == 4) {

        err.Año = false;
        if (this.state.Año.length < 2) {
          err.Año = true;
          //    valido=false;

        }
      }

      if (Campo == 5) {
        err.CodigoSeguridad = false;
        EventoTab = true;

        if (this.state.CodigoSeguridad.length < 3) {
          err.CodigoSeguridad = true;
          //  valido=false;

        }
      }


      this.setState({
        error: err
      });
      var datos = {
        NumeroTarjeta: this.state.NumeroTarjeta,
        NombreTitular: this.state.NombreTitular,
        Mes: this.state.Mes,
        Año: this.state.Año,
        Codigo: this.state.CodigoSeguridad,
        FormaDePago: this.state.FormaDePago
      }
      // this.props.RecibirDatosCompra(datos, EventoTab, false)

      if (this.state.error.NumeroTarjeta == false && this.state.error.NombreTitular == false
        && this.state.error.Mes == false && this.state.error.Año == false && this.state.error.CodigoSeguridad == false) {
        // this.props.RecibirDatosCompra(datos, EventoTab, true)

      }

    }


  }
  ValidarCompra() {
    if(this.state.isRequireDatosTarjetaMembresia){
      conektaHelper.tokenize(this.state.NumeroTarjeta, this.state.NombreTitular, this.state.Mes, this.state.Año, this.state.CodigoSeguridad, this.successCallback, this.errorCallback);
    }else{
      this.AltaMembresia("")
    }

  }


  successCallback(token) {

    this.AltaMembresia(token.id)


  }
  errorCallback(err) {
    // this.state.DatosCompra.charges[0] = {
    //     payment_method: {
    //         type: "card",
    //         token_id: "1234"
    //     }
    // }
    this.showNotification(err.message_to_purchaser, "warning")
  }

  AltaMembresia(token) {
    this.setState({
      VerCargando: true,
      CuerpoCargando: "Generando membresía"
    });
    fetch(Globales.Url + 'Suscripciones/CrearSuscripcionCliente', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({
        idCliente: JSON.parse(Cookies.get('UsuarioTerco')).id,
        NombreCliente: this.state.NombreTitular,
        TokenCard: token,
        isValorCero : !this.state.isRequireDatosTarjetaMembresia,
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) {
          this.showNotification(data.mensaje, "warning")
          this.setState({
            VerCargando: false,
            CuerpoCargando: "",
          });
        } else {
          this.setState({
            VerModalMembresia: true,
            VerCargando: false,
            CuerpoCargando: "",
          });
          window.location.reload();

        }
      });

  }

  BajaMembresia() {
    this.setState({
      VerCargando: true,
      CuerpoCargando: "Cancelando membresía"
    });
    fetch(Globales.Url + 'Suscripciones/CancelarSuscripcion', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({ idCliente: JSON.parse(Cookies.get('UsuarioTerco')).id })
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) {
          this.showNotification(data.mensaje, "warning")
          this.setState({
            VerCargando: false,
            CuerpoCargando: "",
          });
        } else {
          this.setState({
            VerModalMembresia: true,
            VerCargando: false,
            CuerpoCargando: "",
            BajaMembresia: true,
          });

        }
      });

  }



  render() {

    const { classes, ...rest } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );

    const { currentPage, itemsPerPage, totalProductos } = this.state;
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);


    var PagoTarjeta =
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={4} md={6} lg={6}>
            <CustomInput
              labelText="Número de tarjeta"
              id="success"
              formControlProps={{
                fullWidth: true,

              }}
              inputProps={{
                required: this.state.isRequireDatosTarjetaMembresia,
                onChange: this.handleChangeTarjeta,
                onBlur: () => this.ValidarDatos(0, 1),
                value: this.state.NumeroTarjeta,
                name: "NumeroTarjeta",
                id: "NumeroTarjeta",
                type: "text",
                error: this.state.error.NumeroTarjeta,
                onKeyDown: this.handleChangeTarjeta
              }}

            />
          </GridItem>
          <GridItem xs={12} sm={4} md={6} lg={6}>
            <CustomInput
              labelText="Nombre del titular"
              id="success"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                required: this.state.isRequireDatosTarjetaMembresia,
                onChange: this.handleChangeTarjeta,
                onBlur: () => this.ValidarDatos(0, 2),
                value: this.state.NombreTitular,
                autoComplete: "off",
                name: "NombreTitular",
                id: "NombreTitular",
                type: "text",
                error: this.state.error.NombreTitular,

              }}
            />
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem xs={4} sm={4} md={2} lg={2}>
            <CustomInput
              labelText="Mes"
              id="success"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                required: this.state.isRequireDatosTarjetaMembresia,
                onChange: this.handleChangeTarjeta,
                onBlur: () => this.ValidarDatos(0, 3),
                value: this.state.Mes,
                name: "Mes",
                id: "Mes",
                type: "text",
                error: this.state.error.Mes
              }}
            />

          </GridItem>
          <GridItem xs={4} sm={4} md={2} lg={2}>
            <CustomInput
              labelText="Año"
              id="success"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                required: this.state.isRequireDatosTarjetaMembresia,
                onChange: this.handleChangeTarjeta,
                onBlur: () => this.ValidarDatos(0, 4),
                value: this.state.Año,
                name: "Año",
                id: "Año",
                type: "text",
                error: this.state.error.Año
              }}
            />

          </GridItem>
          <GridItem xs={4} sm={4} md={3} lg={3}>
            <CustomInput
              labelText="CVC"
              id="success"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                required: this.state.isRequireDatosTarjetaMembresia,
                onChange: this.handleChangeTarjeta,
                onBlur: () => this.ValidarDatos(0, 5),
                value: this.state.CodigoSeguridad,
                autoComplete: "off",
                name: "CodigoSeguridad",
                id: "CodigoSeguridad",
                type: "password",
                error: this.state.error.CodigoSeguridad
              }}
            />
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem xs={12} sm={4} md={6} lg={6}>
            {/* <div style={{marginTop:'20px'}}>
        <img width="150px" src={LogoOxxo}/>
        </div> */}
            <br />
            <center>
              <Button onClick={() => this.ValidarCompra()} className="mt-5" id="BtnComprar" size="md" color="github">
                {this.state.TipoUsuario == "Cliente" ? "Conviertete en artista" : "Confirmar"}
              </Button>
            </center>

          </GridItem>
        </GridContainer>




      </div>;


    /**TABS PERFIL */

    var Perfil = {
      tabButton: "Perfil",
      tabIcon: AccountCircle,
      tabContent: (
        <div>
          <GridContainer justify="center">

            <GridItem lg={10} md={10} sm={12} xs={12}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Nombre de usuario"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange,
                      value: this.state.Usuario,
                      name: "Usuario",
                      id: "Usuario",
                      type: "text",
                      error: this.state.errorUsuario,
                      disabled: true
                    }} />

                </GridItem>
                <GridItem xs={12} sm={6} md={6}>

                  <CustomInput
                    labelText="Tipo de usuario"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange,
                      value: this.state.IndicadorTerco == true ? this.state.NombreEmpresa : this.state.TipoUsuario,
                      name: "TipoUsuario",
                      id: "TipoUsuario",
                      type: "text",
                      disabled: true
                    }} />
                  {/* <FormControl
                    fullWidth
                    className="mt-5"
                  >
                    <InputLabel
                      htmlFor="TipoUsuario"
                      className={classes.selectLabel}>
                      Tipo de Usuario
          </InputLabel>
                    <Select
                      value={this.state.TipoUsuario}
                      onChange={this.handleChange}
                      disabled={ true }
                      name="TipoUsuario"
                      id="TipoUsuario"
                      inputProps={{
                      }}
                    >
                      <MenuItem disabled>Tipo de Usuario</MenuItem>
                      <MenuItem value='Cliente'>Cliente</MenuItem>
                      <MenuItem value='Artista'>Artista</MenuItem>
                    </Select>
                  </FormControl> */}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Nombre Completo"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange,
                      value: this.state.Nombre,
                      name: "Nombre",
                      id: "Nombre",
                      type: "text",
                      error: this.state.errorNombre
                    }} />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Correo"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange,
                      value: this.state.Correo,
                      name: "Correo",
                      id: "Correo",
                      type: "email",
                      disabled: true,
                      error: this.state.errorCorreo
                    }} />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Teléfono"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange,
                      value: this.state.Telefono,
                      name: "Telefono",
                      id: "Telefono",
                      type: "number",
                      error: this.state.errorTelefono
                    }} />

                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Saldo a favor (Compras Canceladas)"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{

                      required: true,
                      onChange: this.handleChange,
                      value: (new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(this.state.Saldo)),
                      name: "Saldo",
                      id: "Saldo",
                      type: "text",
                      disabled: true

                    }} />

                </GridItem>
              </GridContainer>
              <div className={classes.space50} />

              {
                this.state.EsRedSocial === false ?
                  <div>
                    <h4 className={classes.modalTitle}>Cambiar contraseña</h4>

                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="Contraseña Nueva"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            required: true,
                            onChange: this.handleChange,
                            value: this.state.ContrasenaNueva,
                            name: "ContrasenaNueva",
                            id: "ContrasenaNueva",
                            type: "password",
                            error: this.state.errorContrasenaNueva
                          }} />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="Confirmación de Contraseña"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            required: true,
                            onChange: this.handleChange,
                            value: this.state.ConfirmacionContrasena,
                            name: "ConfirmacionContrasena",
                            id: "ConfirmacionContrasena",
                            type: "password",
                            error: this.state.errorConfirmacionContrasena
                          }} />
                      </GridItem>
                    </GridContainer>
                  </div>
                  : null
              }


            </GridItem>

            <GridItem lg={10} md={10} sm={12} xs={12}>
              <GridContainer justify="center">
                <Button size='md' onClick={() => this.ObtenerImagenes(this.state.id)} round color='success'><Save /> Guardar</Button>

              </GridContainer>
            </GridItem>

          </GridContainer>


        </div>
      )
    };

    var Compras = {
      tabButton: "Compras",
      tabIcon: ShoppingBasket,
      tabContent: (
        <div>



          {
            this.state.Compras.length > 0 && this.state.CargandoCompras == false ?
              <div>
                {
                  this.state.VerDetalleCompra == false ?
                    <div>
                      <GridContainer justify="center">

                        <GridItem lg={10} md={10} sm={12} xs={12}>
                          <div className={classNames(classes.main, classes.mainRaised)}>
                            <div className={classes.container}>
                              <Card plain>
                                <CardBody>
                                  <h3 className={classes.cardTitle}> </h3>
                                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                    <colgroup>
                                      <col style={{ width: "auto" }} />
                                      <col style={{ width: "auto" }} />
                                      <col style={{ width: "auto" }} />
                                      <col style={{ width: "auto" }} />
                                      <col style={{ width: "auto" }} />
                                    </colgroup>
                                    <thead>
                                      <tr>
                                        <th style={{ borderBottom: "3px solid rgba(128, 128, 128, 0.3)"}}>
                                          <b>PEDIDO</b>
                                        </th>
                                        <th style={{ borderBottom: "3px solid rgba(128, 128, 128, 0.3)" }}><b>FECHA</b></th>
                                        <th style={{ borderBottom: "3px solid rgba(128, 128, 128, 0.3)", textAlign:"center" }}><b>TOTAL (ENVÍO INCLUIDO)</b></th>
                                        <th style={{ borderBottom: "3px solid rgba(128, 128, 128, 0.3)" }}><b>ESTATUS</b></th>
                                        <th style={{ borderBottom: "3px solid rgba(128, 128, 128, 0.3)" }}><b></b></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.Compras.map((compra, index) => (
                                        <tr key={index} style={{ height: "50px" }}>
                                          <td>
                                            <span>{compra.FolioPedido}</span>
                                          </td>
                                          <td style={{ textAlign: "center" }}>
                                            <span>{this.CambiarFormatoFecha(compra.FechaDeCompra)}</span>
                                          </td>
                                          <td style={{ textAlign: "right", paddingRight: "30px"}}>
                                            <span>{this.CambiarFormatoPrecio(compra.responseFromConekta._json.amount)}</span>
                                          </td>
                                          <td>
                                            <span>{compra.NombreEstatusPedido == undefined ? "Pendiente de pago" : compra.NombreEstatusPedido}</span>
                                            {compra.Estatus !== 2 && compra.responseFromConekta.charges._json.data[0].payment_method.object === "cash_payment" && (
                                              <Tooltip title="Reenviar ficha de oxxo" placement="top" classes={{ tooltip: classes.tooltip }}>
                                                <IconButton onClick={() => this.setState({ VerModalCorreo: true, DetallesCompra: compra })} aria-label="Reenviar ficha de oxxo">
                                                  <Button color="primary" round justIcon size="sm"><Mail /></Button>
                                                </IconButton>
                                              </Tooltip>
                                            )}
                                            {compra.Estatus == 1 && compra.EstatusPedido == 0 && compra.FechaProximoEstatus != undefined ? (
                                              <div>
                                                <span>
                                                  <Tooltip title="Cancelar compra" placement="top" classes={{ tooltip: classes.tooltip }}>
                                                    <IconButton onClick={() => this.CancelarPedido(compra.FechaProximoEstatus, compra.FechaDeCompra, compra._id)} aria-label="Cancelar pedido">
                                                      <Button color="danger" round justIcon size="sm"><Close /></Button>
                                                    </IconButton>
                                                  </Tooltip>
                                                </span>
                                                <span>
                                                  {'Cancela antes de: ' + new Date(compra.FechaProximoEstatus).toLocaleDateString('en-GB') + ' ' + new Date(compra.FechaProximoEstatus).toLocaleTimeString('en-GB', { hour: "numeric", hour12: "false" })}
                                                </span>
                                              </div>
                                            ) : null}
                                          </td>
                                          <td style={{ textAlign: "center" }}>
                                            <span onClick={() => this.VerDetalleCompra(compra, 0)} style={{ textDecoration: 'underline', cursor: 'pointer' }}>{this.state.VerDetalleCompra ? "Ocultar" : "Detalles"}</span>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </CardBody>
                              </Card>
                            </div>
                          </div>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="center">
                        <center>
                          {
                            this.state.VerDetalleCompra == false ?
                              <Button onClick={() => this.ObtenerCompras()} disabled={this.state.CargandoCompras} size="sm" color="github">
                                {

                                  this.state.CargandoCompras == true ?
                                    <Loader visible={this.state.CargandoCompras} type="ThreeDots" color="#FFFFFF" height={10} width={10} /> :
                                    <Add />
                                }

                                Ver más</Button>
                              : null
                          }
                          <br />
                          <br />
                        </center>


                      </GridContainer>
                    </div>
                    : null
                }







                <div >
                  {
                    this.state.VerDetalleCompra == true ?

                      <DetalleVenta
                        DetallesCompra={this.state.DetallesCompra}
                        eventoCerrarDetalles={() => this.setState({ VerDetalleCompra: false })}
                      />


                      : null
                  }






                </div>
              </div>

              :
              <GridContainer justify="center">
                <GridItem lg={12} md={12} sm={12} xs={12} >
                  <center>
                    {
                      this.state.CargandoCompras == true ?
                        <div>
                          <h3>
                            Obteniendo compras
                          </h3>

                          <Loader visible={true} type="Oval" color="#212121" height={20} width={20} />


                        </div>
                        : null
                    }

                    {
                      this.state.CargandoCompras == false ? <h3>Aún no tienes compras  </h3> : null
                    }




                  </center>
                </GridItem>
              </GridContainer>
          }

        </div>


      )
    };

    var Membresia = {
      tabButton: "Membresia",
      tabIcon: CardMembership,
      tabContent: (
        <div>
          {
            this.state.Membresia == true ?
              <div>
                <GridContainer justify="center">
                  <GridItem xs={10} sm={6} md={6} lg={6}>
                    <Card>
                      <CardHeader color="primary">
                        Información de pago
                      </CardHeader>
                      <CardBody>

                        <GridContainer>

                          <GridItem>
                            <br />
                            <span>Cargo mensual de {'$' + parseFloat((this.state.DatosMembresia.Costo == undefined)?0:this.state.DatosMembresia.Costo / 100, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()} a tarjeta {this.state.DatosMembresia.brand} terminada en {this.state.DatosMembresia.last4}.</span>

                            <br />
                            <br />
                          </GridItem>

                        </GridContainer>

                        <GridContainer justify="center">
                          <Button onClick={() => this.setState({ VerModalCancelarMembresia: true })} color="github" size="sm">Cancelar membresía</Button>
                        </GridContainer>

                      </CardBody>
                    </Card>

                  </GridItem>
                </GridContainer>

                <GridContainer justify="center">
                  <GridItem xs={10} sm={6} md={6} lg={6}>
                    <Card>
                      <CardHeader color="primary">
                        Tarjeta para depósito de comisiones
                      </CardHeader>
                      <CardBody>

                        <GridContainer>

                          <GridItem xs={12} sm={6} md={6}>
                            <CustomInput
                              labelText="Banco"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                required: true,
                                onChange: this.handleChange,
                                value: this.state.BancoComision,
                                name: "BancoComision",
                                id: "BancoComision",
                                type: "text",
                                error: this.state.errorBancoComision

                              }} />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <CustomInput
                              labelText="Número de tarjeta"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                required: true,
                                onChange: this.handleChange,
                                value: this.state.NumeroTarjetaComision,
                                name: "NumeroTarjetaComision",
                                id: "NumeroTarjetaComision",
                                type: "text",
                                error: this.state.errorNumeroTarjetaComision
                              }} />
                          </GridItem>

                        </GridContainer>

                        <GridContainer justify="center">
                          <Button onClick={() => this.ActualizarTarjetaComisiones()} color="github" size="sm">

                            Guardar
                          </Button>
                        </GridContainer>

                      </CardBody>
                    </Card>

                  </GridItem>
                </GridContainer>
              </div> :
              <div>
                <GridContainer>
                  <GridItem xs={10} sm={10} md={12} lg={12}>
                    <center>
                      <h4>Beneficios de ser artista</h4>
                      <h4>Ventas</h4>
                      <h4>Tienda</h4>
                    </center>

                  </GridItem>
                </GridContainer>

                <GridContainer justify="center">
                  <GridItem xs={10} sm={10} md={6} lg={6} >

                    <Card>
                      <CardHeader color="primary">
                        Membresía
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          <GridItem lg={12} md={12} sm={12} xs={12}>
                            {
                              this.state.PrecioMembresiaDescuento > 0 ?
                                <center>

                                  <span style={{ textDecoration: 'line-through', color: '#f44336', fontSize: '1.1em' }}>
                                    {'$' + parseFloat(this.state.PrecioMembresia, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()}
                                  </span>



                                </center>
                                :
                                null
                            }


                          </GridItem>

                          <GridItem lg={12} md={12} sm={12} xs={12}>
                            <center>


                              <span style={{ fontSize: '3em', fontWeight: '2em' }}>
                                {
                                  /*this.state.PrecioMembresiaDescuento == 0 ?
                                    '$' + parseFloat(this.state.PrecioMembresia, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()

                                    :
                                    '$' + parseFloat(this.state.PrecioMembresiaDescuento, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()*/
                                    '$' + parseFloat(this.state.PrecioMembresiaDescuento, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()

                                }


                              </span>


                            </center>

                          </GridItem>

                          <GridItem lg={12} md={12} sm={12} xs={12}>
                            {
                              this.state.PrecioMembresiaDescuento > 0 ?
                                <center>

                                  <span style={{ color: '#808080', fontSize: '1.1em', marginTop: "10px" }}>
                                    {'Vencimiento: ' + new Date(new Date(this.state.FechaVencimientoMembresia).setDate(new Date(this.state.FechaVencimientoMembresia).getDate() + 1)).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })}

                                  </span>


                                </center>
                                :
                                null
                            }


                          </GridItem>



                        </GridContainer>
                        <GridContainer justify="center">
                          {/* 
                  <GridItem lg={8} md={8} sm={12} xs={12}>
                    <center>
                      <GridItem lg={12} md={12} sm={12} xs={12}>
                        <AttachMoney /> Ventas

                          </GridItem>

                      <GridItem lg={12} md={12} sm={12} xs={12}>
                        <StoreMallDirectory /> Tienda

                          </GridItem>

                    </center>

                  </GridItem> */}

                          <GridItem lg={12} md={12} sm={12} xs={12}>
                            {PagoTarjeta}

                          </GridItem>

                        </GridContainer>

                      </CardBody>
                    </Card>


                  </GridItem>




                </GridContainer>
              </div>


          }

        </div>
      )
    };

    var Ventas = {
      tabButton: "Ventas",
      tabIcon: AttachMoney,
      tabContent: (
        <div>
          {
            this.state.Membresia == true ?
              <div>

                {
                  this.state.Ventas.length > 0 && this.state.CargandoVentas == false ?
                    <div>
                      {
                        this.state.VerDetalleCompra == false ?
                          <GridContainer justify="center" style={{ marginTop: "50px" }}>

                            <GridItem lg={10} md={10} sm={12} xs={12}>
                              <div className={classNames(classes.main, classes.mainRaised)}>
                                <div className={classes.container}>

                                  <Card plain>
                                    <CardBody plain>
                                      <h3 className={classes.cardTitle}> </h3>
                                      <div style={{ backgroundColor: "#DEDEDE", padding: "10px", maxWidth: "300px", borderRadius: "5px" }}>
                                        <h4>{"Comisión Pendiente: $" + (new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(this.state.ImporteComisionesPendientes)) }  </h4>
                                      </div>

                                      <Table
                                        tableHead={[
                                          "PEDIDO",
                                          "FECHA",
                                          "ESTATUS",
                                          "TOTAL",
                                        ]}
                                        tableData={this.state.Ventas.map((compra, index) =>
                                          [
                                            <span>{compra.FolioPedido}</span>,
                                            <span>{this.CambiarFormatoFecha(compra.FechaDeCompra)}</span>,
                                            <span>{compra.NombreEstatusPedido == undefined ? "Pendiente de pago" : compra.NombreEstatusPedido}</span>,
                                            <span>{
                                              this.CambiarFormatoPrecio(compra.responseFromConekta._json.amount)
                                            }</span>,
                                            <span onClick={() => this.VerDetalleCompra(compra, 1)} style={{ textDecoration: 'underline', cursor: 'pointer' }}>  {this.state.VerDetalleCompra ? "Ocultar" : "Detalles"}  </span>,
                                          ]
                                        )
                                        }
                                        tableShopping
                                        customHeadCellClasses={[
                                          classes.textCenter,
                                          classes.description,
                                          classes.description,
                                          classes.textRight,
                                          classes.textRight,
                                          classes.textRight
                                        ]}
                                        customCellClasses={[
                                          classes.tdName,
                                          classes.customFont,
                                          classes.customFont,
                                          classes.tdNumber,
                                          classes.tdNumber + " " + classes.tdNumberAndButtonGroup,
                                          classes.tdNumber + " " + classes.textCenter
                                        ]}
                                      />
                                    </CardBody>
                                  </Card>
                                </div>
                              </div>
                            </GridItem>
                          </GridContainer>
                          : null
                      }


                      <div >
                        {
                          this.state.VerDetalleCompra == true ?

                            <DetalleVenta
                              EsVenta={this.state.EsVenta}
                              DetallesCompra={this.state.DetallesCompra}
                              eventoCerrarDetalles={() => this.setState({ VerDetalleCompra: false })}
                            />


                            :
                            null
                        }
                        <center>
                          {
                            this.state.VerDetalleCompra == false ?
                              <Button onClick={() => this.ObtenerCompras()} size="sm" color="github">
                                {

                                  this.state.CargandoVentas == true ?
                                    <Loader visible={this.state.CargandoVentas} type="ThreeDots" color="#FFFFFF" height={10} width={10} /> :
                                    <Add />
                                }

                                Ver más</Button>
                              : null
                          }
                        </center>
                        <br />
                        <br />





                      </div>


                    </div>
                    :
                    <GridContainer justify="center">
                      <GridItem lg={12} md={12} sm={12} xs={12} >
                        <center>
                          {
                            this.state.CargandoVentas == true ?
                              <div>
                                <h3>
                                  Obteniendo ventas
                                </h3>

                                <Loader visible={true} type="Oval" color="#212121" height={20} width={20} />


                              </div>
                              : null
                          }

                          {
                            this.state.CargandoVentas == false ? <h3>Aún no tienes ventas  </h3> : null
                          }




                        </center>
                      </GridItem>
                    </GridContainer>

                }

              </div>
              :
              <center>
                <h4>Termina tu registro</h4>
              </center>
          }


        </div>
      )
    };

    var Disenos = {
      tabButton: "Diseños",
      tabIcon: Camisa,
      tabContent: (
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={2}>
              <List>
                <ListItem onClick={() => this.CambiarDisenosFavoritos(1)} button>
                  <Camisa /> &nbsp;
                  {
                    this.state.SeleccionDisenos == true ?
                      <ListItemText primary="Mis diseños" />
                      :
                      <ListItemText secondary="Mis diseños" />

                  }
                </ListItem>

                <ListItem onClick={() => this.CambiarDisenosFavoritos(2)} button>
                  <Favorite /> &nbsp;

                  {
                    this.state.SeleccionFavoritos == true ?
                      <ListItemText primary="Favoritos" />
                      :
                      <ListItemText secondary="Favoritos" />
                  }

                </ListItem>


              </List>

            </GridItem>
            <GridItem xs={12} sm={12} md={10}>
              {
                this.state.cargandoDisenios == true ?
                (
                  <div>
                  <center>
                    <h2>Cargando diseños</h2>
                    <Loader
                      visible={true}
                      type="Oval"
                      color="#212121"
                      height={40}
                      width={40}
                    />
                  </center>
                </div>
                ):<div></div>
              }
              <GridContainer justify="center">

                {
                  (this.state.Disenos.length > 0 && this.state.cargandoDisenios == false) ?
                    this.state.Disenos.map(Disenos =>
                      <GridItem xs={6} sm={4} md={4} >
                        <div style={{ minWidth: "300px" }}>
                          <center>
                            <img
                              style={{ width: "250px", height: "250px", objectFit: "cover", objectPosition: "center center" }}
                              src={"data:image/png;base64," + Disenos.ImagenPro} alt="..."
                            />
                          </center>
                        </div>
                        {/* <img width="200" height="150" src={Globales.Url + "Productos/" + Disenos.RutaImagen} alt="..." />
                                <div
                                  className={classes.coloredShadow}
                                  style={{
                                    backgroundImage: "url(" + avatar + ")",
                                    opacity: "1"
                                  }}
                                /> */}

                        <center>


                          <h4 className={classes.cardTitle}>
                            {Disenos.nombre}
                          </h4>


                          <Tooltip title="Editar diseño" placement="top" classes={{ tooltip: classes.tooltip }}>
                            <IconButton onClick={() => this.VolverDiseñar(Disenos)} aria-label="Editar diseño">

                              <Edit fontSize="small" color="action" />
                            </IconButton>
                          </Tooltip>

                          {
                            this.state.Membresia == true && this.state.SeleccionDisenos == true && this.state.TipoUsuario == "Artista" ?
                              <Tooltip disabled={Disenos.EnTienda == true ? true : false} title="Añadir a mi tienda" placement="top" classes={{ tooltip: classes.tooltip }}>
                                <IconButton onClick={() => this.AgregarDisenoATienda(Disenos)} fontSize="small" color="action" aria-label="Añadir a mi tienda">
                                  <StoreMallDirectory />
                                </IconButton>
                              </Tooltip>
                              : null
                          }

                          {
                            this.state.SeleccionDisenos == true ?
                              <Tooltip disabled={Disenos.EnTienda == true ? true : false} title={Disenos.EnTienda == true ? "Diseño en tienda" : "Eliminar diseño"} placement="top" classes={{ tooltip: classes.tooltip }}>
                                <IconButton onClick={() => this.EliminarDisenoGuardado(Disenos._id)} fontSize="small" color="action" aria-label="Eliminar diseño">
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                              :
                              <Tooltip title={"Eliminar"} placement="top" classes={{ tooltip: classes.tooltip }}>
                                <IconButton onClick={() => this.EliminarFavorito(Disenos._id)} fontSize="small" color="action" aria-label="Eliminar">
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                          }
                        </center>
                      </GridItem>
                    )
                    :
                    (this.state.cargandoDisenios == true?
                      null
                      :
                      <GridContainer justify="center">
                      <GridItem lg={12} md={12} sm={12} xs={12} >
                        <center>
                          {this.state.SeleccionDisenos == true ?
                            <h3>Aún no tienes diseños </h3>
                            :
                            <h3>Aún no tienes favoritos </h3>
                          }

                          <Link to="/SeleccionaTuDiseño">
                            <h4 style={{ textDecoration: 'underline', cursor: 'pointer', color: 'black' }}>Diseñador</h4>

                          </Link>
                        </center>
                      </GridItem>
                    </GridContainer>
                    )
                }

              </GridContainer>
              <div className={classes.pagination} style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <Pagination
                  count={Math.ceil(totalProductos / itemsPerPage)}
                  page={currentPage}
                  onChange={this.handlePageChange}
                  color="standard"
                  size="large"
                  showFirstButton
                  showLastButton
                  style={{
                    marginBottom: '20px',
                  }}
                />
              </div>
            </GridItem>
          </GridContainer>
        </div>
      )
    };


    var Tienda = {
      tabButton: "tienda",
      tabIcon: StoreMallDirectory,
      tabContent: (
        <div >
          {
            this.state.Membresia == true ?

              this.state.VerTienda == true ? <TiendaArtista TiendaPropia={this.state.VerTiendaArtista} idCliente={JSON.parse(Cookies.get("UsuarioTerco")).id} EventoEliminarTienda={this.EventoEliminarTienda} /> : null

              :
              <center>
                <h4>Termina tu registro</h4>
              </center>
          }


        </div>
      )
    };

    /**cliente sin membresia */

    var Artista = {
      tabButton: "¡Artista!",
      tabIcon: Grade,
      tabContent: (
        <div>

          <GridContainer>
            <GridItem xs={10} sm={10} md={12} lg={12}>
              <center>
                <h4>Beneficios de ser artista</h4>
                <h4>Ventas</h4>
                <h4>Tienda</h4>
              </center>

            </GridItem>
          </GridContainer>

          <GridContainer justify="center">
            <GridItem xs={10} sm={10} md={6} lg={6} >

              <Card>
                <CardHeader color="primary">
                  Membresía
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      {
                        this.state.PrecioMembresiaDescuento > 0 ?
                          <center>

                            <span style={{ textDecoration: 'line-through', color: '#f44336', fontSize: '1.1em' }}>
                              {'$' + parseFloat(this.state.PrecioMembresia, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()}
                            </span>



                          </center>
                          :
                          null
                      }


                    </GridItem>

                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <center>


                        <span style={{ fontSize: '3em', fontWeight: '2em' }}>
                          {
                            this.state.PrecioMembresiaDescuento == 0 ?
                              '$' + parseFloat(this.state.PrecioMembresia, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()

                              :
                              '$' + parseFloat(this.state.PrecioMembresiaDescuento, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()

                          }


                        </span>


                      </center>

                    </GridItem>

                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      {
                        this.state.PrecioMembresiaDescuento > 0 ?
                          <center>

                            <span style={{ color: '#808080', fontSize: '1.1em', marginTop: "10px" }}>
                              {'Vencimiento: ' + new Date(new Date(this.state.FechaVencimientoMembresia).setDate(new Date(this.state.FechaVencimientoMembresia).getDate() + 1)).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                            </span>


                          </center>
                          :
                          null
                      }


                    </GridItem>



                  </GridContainer>
                  <GridContainer justify="center">
                    {/* 
                    <GridItem lg={8} md={8} sm={12} xs={12}>
                      <center>
                        <GridItem lg={12} md={12} sm={12} xs={12}>
                          <AttachMoney /> Ventas

                            </GridItem>

                        <GridItem lg={12} md={12} sm={12} xs={12}>
                          <StoreMallDirectory /> Tienda

                            </GridItem>

                      </center>

                    </GridItem> */}

                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      {PagoTarjeta}

                    </GridItem>

                  </GridContainer>

                </CardBody>
              </Card>


            </GridItem>




          </GridContainer>



        </div>
      )
    };









    return (
      <div>
        <Header
          color="dark"
          Tienda={this.state.VerTiendaArtista}
          TipoUsuario={this.state.TipoUsuario}
          CambiarLogo={this.CambiarLogo}
          Logo={this.state.Logo}
          links={<HeaderLinks dropdownHoverColor="dark" />}
          fixed
          {...rest}
        />
        <Parallax
          image={this.state.Portada == null ? require("assets/img/terco-img/BannerPerfilArtistas.jpg") : this.state.Portada}
          filter="dark"
          className={classes.parallax}
        />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>


            <GridContainer justify="center">

              <GridItem xs={12} sm={6} md={6}>
                <div className={classes.profile}>

                  {
                    this.state.VerTiendaArtista == true ?
                      <div id="ContenedorImagenPerfil" className="ContenedorImagenPerfil">


                        <img onClick={() => this.setState({ show: true, TituloModal: 'Editar foto de perfil' })}
                          style={{ 
                            cursor: 'pointer', 
                            backgroundColor: 'white', 
                            borderRadius: '50%',
                            objectFit: 'cover',

                          }}
                          src={this.state.FotoPerfil == null ? UsuarioBlanco : this.state.FotoPerfil}
                          height="150px"
                        />

                      </div>
                      :
                      <div id="ContenedorImagenPerfil" className="ContenedorImagenPerfil">


                        <img onClick={() => this.setState({ show: true, TituloModal: 'Editar foto de perfil' })}
                          style={{ cursor: 'pointer', backgroundColor: 'white', borderRadius: '50%' }}
                          onMouseOver={() => this.setState({ VerBotonFotoPerfil: true })} onMouseOutCapture={() => this.setState({ VerBotonFotoPerfil: false })}
                          src={this.state.FotoPerfil == null ? UsuarioBlanco : this.state.FotoPerfil}
                          width="150px" height="150px"
                        />
                        {
                          this.state.VerBotonFotoPerfil ? <button id="btnImagen" className="btnImagen">Editar</button> : null
                        }
                      </div>

                  }


                  {/* <div>
                    <img src={christian} alt="..." className={imageClasses} />
                  </div> */}
                  <div className={classes.name}>

                    <h3> {this.state.Nombre} </h3>
                  </div>
                </div>
                {
                  this.state.VerTiendaArtista == false ?
                    <div className={classes.follow}>
                      <Tooltip
                        id="tooltip-top"
                        title="Editar Portada"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          // href='/signup-page'
                          justIcon
                          round
                          color="github"
                          className={classes.followButton}
                          onClick={() => this.setState({ show: true, TituloModal: 'Editar portada' })}
                        >
                          <Edit className={classes.followIcon} />
                        </Button>
                      </Tooltip>
                    </div>
                    :
                    null
                }


              </GridItem>


            </GridContainer>


            {
              this.state.VerTiendaArtista == true ?
                <GridContainer>
                  <GridItem lg={12} md={12} sm={12} xs={12}>
                    <TiendaArtista TiendaPropia={this.state.VerTiendaArtista} idCliente={this.state.id} EventoEliminarTienda={this.EventoEliminarTienda} />


                  </GridItem>
                </GridContainer>
                :
                <div className={classes.profileTabs}>

                  {
                    this.state.VerTabs == true ?


                      this.state.TipoUsuario == "Cliente" ?
                        <NavPills active={this.state.IndexTabCliente} alignCenter color="primary"
                          tabs={[
                            Perfil,
                            Compras,
                            Disenos,
                            Artista
                          ]}
                        />
                        : this.state.TipoUsuario == "Artista" && this.state.IndicadorTerco == true ?
                          <NavPills active={this.state.IndexTabArtista} alignCenter color="primary"
                            tabs={[
                              Perfil,
                              Compras,
                              Ventas,
                              Disenos,
                              Tienda
                            ]}
                          />
                          :
                          <NavPills active={this.state.IndexTabArtista} alignCenter color="primary"
                            tabs={[
                              Perfil,
                              Compras,
                              Membresia,
                              Ventas,
                              Disenos,
                              Tienda
                            ]}
                          />




                      : null
                  }





                </div>

            }

          </div>




        </div>
        <Snackbar
          place="tr"
          color={this.state.colorNotificacion}
          message={this.state.mensaje}
          open={this.state.br}
          closeNotification={() => this.setState({ br: false })}
          close
        />
        {
          this.state.VerAgregarTienda == true ?
            <ModalAgregarTienda Disenos={this.state.DisenosGrupo} EventoModalAgregarTienda={this.EventoModalAgregarTienda} VerAgregarTienda={this.state.VerAgregarTienda} />
            : null
        }
        {
          this.state.show ? <div id="">
            <ModalEditar
              Titulo={this.state.TituloModal}
              liveDemo={this.state.show}
              Imagenes={{ Portada: this.state.Portada, FotoPerfil: this.state.FotoPerfil, Logo: this.state.Logo }}
              size='large'
              eventClick={this.eventoModal}
              eventClose={this.eventoClose}
            />
          </div> : null
        }

        {
          this.state.VerModalCancelar == true ?
            <ModalCancelar
              VerModalCancelar={this.state.VerModalCancelar}
              FechaParaCancelar={this.state.FechaParaCancelar}
              EventoModalCancelar={this.EventoModalCancelar}
              idPedidoCancelacion={this.state.idPedidoCancelacion}
            />
            : null
        }

        {
          this.state.VerModalMembresia == true ?
            <ModalMembresia VerModal={this.state.VerModalMembresia} TipoMensaje={this.state.BajaMembresia} />
            : null
        }
        {
          this.state.VerModalCancelarMembresia == true ?
            <ModalCancelarMembresia
              Titulo='Cancelar membresía'
              body={'¿Seguro que deseas cancelar tu membresía?'}
              liveDemo={this.state.VerModalCancelarMembresia}
              eventClick={this.eventoModalCancelarMembresia}
            />
            : null
        }
        {
          this.state.VerModalCorreo == true ?
            <ModalCorreoOxxo
              VerModal={this.state.VerModalCorreo}
              DetallesCompra={this.state.DetallesCompra}
              eventClick={this.EventoModalCorreo}
            />
            : null
        }

        <ModalCargando Ver={this.state.VerCargando} Cuerpo={this.state.CuerpoCargando} />

      </div>
    );
  }
}

export default withStyles(profilePageStyle)(ProfilePage);
