/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
import UserIcon from "@material-ui/icons/Person";
// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import ViewDay from "@material-ui/icons/ViewDay";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import AccountBalance from "@material-ui/icons/AccountBalance";
import ArtTrack from "@material-ui/icons/ArtTrack";
import ViewQuilt from "@material-ui/icons/ViewQuilt";
import LocationOn from "@material-ui/icons/LocationOn";
import Fingerprint from "@material-ui/icons/Fingerprint";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Apps from "@material-ui/icons/AttachMoney";
import Store from "@material-ui/icons/Store";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PersonAdd from "@material-ui/icons/PersonAdd";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import Error from "@material-ui/icons/Error";
import Cookies from 'js-cookie'
import Favorite from "@material-ui/icons/Favorite";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import ModalAccion from '../Modales/ModalAccion.jsx';
import Globales from "utils/Globales";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { Input } from "@material-ui/core";

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // liveDemo: false
    };
    this.eventoModal = this.eventoModal.bind(this);
  }

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  openModal = () => {
    this.setState({ liveDemo: true });
  }

  irPerfil = () => {
    //console.log("ir perfil")
    window.location.href = '/#/Perfil';
    window.location.reload();
  }

  eventoModal(e) {
    if (!e) {
      this.setState({ liveDemo: false });
      return;
    }
    this.cerrarSesion(this.seleccionado);

  }
  componentDidMount() {
  }
  componentWillMount() {
  }

  cerrarSesion() {
    this.setState({ liveDemo: false });
    var sesion = {
      id: '',
      Carrito: "",
      ListaCarrito: [],
      ListaDeseos: [],
      Usuario: "",
      Nombre: "",
      Usuario: "",
      Correo: "",
      Telefono: "",
      TipoUsuario: "",
      token: "",
      IndicadorTerco: "",
    }
    Cookies.set("UsuarioTerco", JSON.stringify(sesion))
    Cookies.set("Id", "")
    localStorage.setItem("idUser", "")

    Globales.token = '';
    //Limpiar sesion del lado del server
    window.location.href = '/#/login';
  }
  CambiarRuta(ruta) {
    window.location.href = ruta;
  }

  render() {
    const { classes, dropdownHoverColor } = this.props;
    const popover = {
      position: 'absolute',
      right: '0',
    }
    return (
      <div style={popover}>
        <List className={classes.list + " " + classes.mlAuto}>
          {/* <ListItem className={classes.listItem}>
            <CustomDropdown
              noLiPadding
              navDropdown
              hoverColor={dropdownHoverColor}
              buttonText="Examples"
              buttonProps={{
                className: classes.navLink,
                color: "transparent"
              }}
              buttonIcon={ViewCarousel}
              dropdownList={[
                <Link to="/about-us" className={classes.dropdownLink}>
                  <AccountBalance className={classes.dropdownIcons} /> About Us
            </Link>,
                <Link to="/carrito" className={classes.dropdownLink}>
                  <ArtTrack className={classes.dropdownIcons} /> Blog Post
            </Link>,
                <Link to="/blog-posts" className={classes.dropdownLink}>
                  <ViewQuilt className={classes.dropdownIcons} /> Blog Posts
            </Link>,
                <Link to="/contact-us" className={classes.dropdownLink}>
                  <LocationOn className={classes.dropdownIcons} /> Contact Us
            </Link>,
                <Link to="/landing-page" className={classes.dropdownLink}>
                  <ViewDay className={classes.dropdownIcons} /> Landing Page
            </Link>,
                <Link to="/login-page" className={classes.dropdownLink}>
                  <Fingerprint className={classes.dropdownIcons} /> Login Page
            </Link>,
                <Link to="/pricing" className={classes.dropdownLink}>
                  <AttachMoney className={classes.dropdownIcons} /> Pricing Page
            </Link>,
                <Link to="/carrito" className={classes.dropdownLink}>
                  <ShoppingBasket className={classes.dropdownIcons} /> Shopping Cart
            </Link>,
                <Link to="/ecommerce-page" className={classes.dropdownLink}>
                  <Store className={classes.dropdownIcons} /> Ecommerce Page
            </Link>,
                <Link to="/diseñador3D" className={classes.dropdownLink}>
                  <ShoppingCart className={classes.dropdownIcons} /> Product Page
            </Link>,
                <Link to="/profile-page" className={classes.dropdownLink}>
                  <AccountCircle className={classes.dropdownIcons} /> Profile Page
            </Link>,
                <Link to="/signup-page" className={classes.dropdownLink}>
                  <PersonAdd className={classes.dropdownIcons} /> Signup Page
            </Link>,
                <Link to="/error-page" className={classes.dropdownLink}>
                  <Error className={classes.dropdownIcons} /> Error Page
            </Link>
              ]}
            />
          </ListItem> */}
          {/* <ListItem className={classes.listItem}>
            <Button href="/ecommerce-page" className={classes.navLink} color="transparent"><Apps />Galeria de Productos</Button>
          </ListItem>  */}
          {/* Inicio de sesión o sesión de usuario*/}

          {
            JSON.parse(Cookies.get("UsuarioTerco")).id != "" ?
              <ListItem className={classes.listItem}>
                <CustomDropdown
                  noLiPadding
                  navDropdown
                  hoverColor={dropdownHoverColor}
                  buttonText={JSON.parse(Cookies.get("UsuarioTerco")).Usuario}
                  buttonProps={{
                    className: classes.navLink,
                    color: "transparent"
                  }}
                  buttonIcon={UserIcon}
                  dropdownList={[
                    <Link to="#" onClick={() => this.irPerfil()} className={classes.dropdownLink} >Perfil</Link>,
                    // <Link to="/lista-deseos" className={classes.dropdownLink} > Lista de deseos </Link>,
                    <Link to="#" className={classes.dropdownLink} onClick={() => this.openModal()} >Cerrar Sesión
                    </Link>
                  ]}
                />
              </ListItem>
              :
              <ListItem className={classes.listItem}>
                <Link to="/login" className={classes.dropdownLink} >Iniciar sesión</Link>
              </ListItem>
          }


          <ListItem className={classes.listItem}>
            <Link to="/" className={classes.dropdownLink} >Inicio</Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="/SeleccionaTuDiseño" className={classes.dropdownLink} >Diseñador</Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="/Artistas" className={classes.dropdownLink} >Artistas</Link>
          </ListItem>
          
          {/* <ListItem className={classes.listItem}>
                   <Link to="/diseñador3D" className={classes.dropdownLink} >Listos por fabricar</Link>
            </ListItem> */}

          {/* <ListItem className={classes.listItem}>
                   <Link to="/Tienda/5dd57d039d4bb62c84e77d16" className={classes.dropdownLink} >Ver tienda</Link>
               
            </ListItem> */}




          <ListItem className={classes.listItem}>

            <Link className={classes.dropdownLink} to="/carrito">
              {
                window.innerWidth < 960 ? "Carrito " + "(" + JSON.parse(Cookies.get("UsuarioTerco")).Carrito + ")" :
                  <Button
                    color="white"
                    className={classes.navButton}
                    round
                    size='sm'

                  >
                    <ShoppingCart className={classes.icons} />

                    {JSON.parse(Cookies.get("UsuarioTerco")).Carrito}
                  </Button>
              }

            </Link>







          </ListItem>
          {/* <ListItem className={classes.listItem}>
          <Link className={classes.dropdownLink}  to="/lista-deseos">

          {
                window.innerWidth < 960 ? "Favoritos" :
              
                <Button 
                color="white"

                  justIcon
                  className={classes.navButton}
                  round
                  size='sm'
                >
                  <Favorite />
                </Button>
               
             
                }
       
       </Link>
          </ListItem > */}

        </List>
        <div id="">
          <ModalAccion
            Titulo='Cerrar Sesión'
            body={'¿Seguro que deseas cerrar sesión ' + JSON.parse(Cookies.get('UsuarioTerco')).Usuario + '?'}
            liveDemo={this.state.liveDemo}
            eventClick={this.eventoModal}
          />
        </div>
      </div>
    );
  }
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};

export default withStyles(headerLinksStyle)(HeaderLinks);
