
// @material-ui/core components

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import Globales from "utils/Globales";
import Card from "components/Card/Card.jsx";
import Slide from "@material-ui/core/Slide";
import withStyles from "@material-ui/core/styles/withStyles";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";
import TablaProducto from "./TablaProductos.jsx"
import DialogActions from "@material-ui/core/DialogActions";
import Salir from "@material-ui/icons/ExitToApp";
import Money from "@material-ui/icons/AttachMoney";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Cookies from 'js-cookie'
import { Link } from "react-router-dom";





const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class ModalConfiguracionProducto extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.escFunction = this.escFunction.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.eventoTabla = this.eventoTabla.bind(this);
        this.state = {
            checked: [1, 3, 5],
            idModelo3D: '',
            productosCarrito: [],
            colorNotificacion: 'success',
            mensaje: '',
            idCategoria: '',
            cargando: {
                spinnerCategoria: "true",
                spinnerModelo: "false"
            },
            Carrito:false

        }
      

    }
    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({
            checked: newChecked
        });
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.props.eventClick()
        }
    }

    componentDidMount() {

        document.addEventListener("keydown", this.escFunction, false);

    }
    componentWillMount() {

    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    eventoTabla(productos) {
     this.state.productosCarrito = productos
     

    }
    ValidarProductos(e) {
        var productosParaGuardar = [];

        
        this.state.productosCarrito.map(m => {

            if (m.Marca == true) {
                if (m.Cantidad > 0) {
                    productosParaGuardar.push(m)
                }
            }

        })

        if (productosParaGuardar.length > 0) {
        
            this.AgregarCarrito(e, productosParaGuardar);

        } else {
            this.setState({
                colorNotificacion: 'warning',
                mensaje: 'Debe seleccionar al menos un producto',
                br: true
            })
            this.showNotification()
        }


    }
    AgregarCarrito(opcion, productos) {
    var ProductoFinal=[];
    productos.map(m=>{
        ProductoFinal.push({_id:m._id, Cantidad:m.Cantidad, atributosAgregados:m.atributosAgregados})
    })


        fetch(Globales.Url + 'Carrito/Carrito/AgregarProductoCarrito', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }, body: JSON.stringify({ idUsuario: JSON.parse(Cookies.get('Usuario')).id, Productos: ProductoFinal })
        })
            .then(res => res.json())
            .then(data => {
            
                if (data.error) { 

                } else { 
                    /**TRUE CONFIRMAR COMPRA */
            /**FALSE SEGUIR  COMPRANDO */
                    if(opcion ){    
                    this.ObtenerCarrito()
                    }else{
                        this.props.eventoModal(false)
                    }
                   
                }
            });
    }




    ObtenerCarrito() {

        fetch(Globales.Url + 'Carrito/Carrito/ConsultarCarrito', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }, body: JSON.stringify({ idCliente: JSON.parse(Cookies.get('Usuario')).id })
        })
          .then(res => res.json())
          .then(data => {
            if (data.error) { //Error al obtener los registros
           
            } else { //Obtuvo los registros correctamente
      Cookies.set('Carrito',parseInt (data.data.length));
      //window.location.href="/carrito";
     document.getElementById("Carrito").click();
           
            }
          });
      }
      




    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false });
                this.setState(x);
            }.bind(this),
            3000
        );
    }

    render() {
        const { classes, ...rest } = this.props;

    
        return (

            <div >
                <Link to ="/carrito"><button id="Carrito"></button></Link>


                {/* SIGNUP MODAL START */}
                <Dialog
                    classes={{
                        root: classes.modalRoot,
                        paper: classes.modal + " " + classes.modalSignup
                    }}
                    id="mdlConfiguracionProducto"
                    open={this.props.verModal}
                    TransitionComponent={Transition}
                    keepMounted

                    aria-labelledby="signup-modal-slide-title"
                    aria-describedby="signup-modal-slide-description"
                >
                    <Card plain className={classes.modalSignupCard}>
                        <DialogTitle
                            id="signup-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                        >
                            <Button
                                simple
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                onClick={() => this.props.eventoModal(true)}
                            >
                                {" "}
                                <Close className={classes.modalClose} />
                            </Button>
                            <h3
                                className={`${classes.cardTitle} ${
                                    classes.modalTitle
                                    }`}
                            >
                                Productos
                        </h3>
                        </DialogTitle>
                        <DialogContent id="signup-modal-slide-description" className={classes.modalBody}>



                            <TablaProducto eventoTabla={this.eventoTabla} idModelo={this.props.idModelo} />



                        </DialogContent>
                        <DialogActions className={classes.modalFooter}>
                            <Button onClick={() => this.ValidarProductos(true)} round size='md' color="github"> <Money /> Confirmar compra</Button>
                            <Button onClick={() => this.ValidarProductos(false)} round size='md' color="danger" ><Salir /> Seguir comprando</Button>
                        </DialogActions>
                    </Card>

                    <Snackbar
                        place="tc"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ br: false })}
                        close
                    />
                </Dialog>
                {/* SIGNUP MODAL END */}
            </div >

        );
    }
}




export default withStyles(modalStyle)(ModalConfiguracionProducto);
