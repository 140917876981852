import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx"; import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Done from '@material-ui/icons/Done';
import CardBody from "components/Card/CardBody.jsx";
import TreeView from '@material-ui/lab/TreeView';
import { TreeItem } from '@material-ui/lab'
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import { Save, Clear } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';


const useTreeItemStyles = makeStyles(theme => ({
    root: {
      color: theme.palette.text.primary,
      '&:focus > $content': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[100]})`,
        color: 'var(--tree-view-color)',
      },
    },
    content: {
      color: theme.palette.text.primary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
      '& $content': {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
      fontSize:"1em"
    },
  }));
  
  function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

    return (
      <TreeItem
        label={
          <div className={classes.labelRoot}>
            <LabelIcon color="inherit" className={classes.labelIcon} />
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          </div>
        }
        style={{
          '--tree-view-color': color,
          '--tree-view-bg-color': bgColor,
        }}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          group: classes.group,
          label: classes.label,
        }}
        {...other}
      />
    );
  }
  
  StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
  };
  
  const useStyles = makeStyles({
    root: {
      height: 264,
      flexGrow: 1,
      maxWidth: 400,
    },
  });
  



  
export default class AgregarCategoriaModelo extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      NombreCategoriaModelo: '',
      selectedEnabled: 'a',
      CategoriaPadre: '',
      MostrarModal: false,
      idCategoriaPadre: '',
      Descripcion: '',
      _id: '',
      categoriasModelos3D: [],
      errorNombre: false,
      categoriasModelosTreeView:[],
      errorCategoriaPadre: false,
      errorDescripcion: false,
      colorNotificacion: 'success',
      mensaje: '',
      br: false,
      disable: false
    }
    global.NombreCategoria = '';
    this.obtenerAllCategoriasModelos = this.obtenerAllCategoriasModelos.bind(this);
    this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
    this.obtenerCategoriasTreeView = this.obtenerCategoriasTreeView.bind(this);
    this.handleChangeTreeView = this.handleChangeTreeView.bind(this);
  }
   componentDidMount() {
    //Verificar si es editar

    this.obtenerAllCategoriasModelos();
    this.obtenerCategoriasTreeView();
    // var response =[]
    // for(var keyArrayCategoria = 0; keyArrayCategoria < arrayCategorias.length; keyArrayCategoria++){
    // response.push(this.obtenerEtiquetaItem(arrayCategorias[keyArrayCategoria]));
    // }
  }

  componentWillUnmount() {
  };

  obtenerAllCategoriasModelos() {
    fetch(Globales.Url + 'Modelos/Categoria/ObtenerTodasCategorias', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ categoriasModelos3D: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ categoriasModelos3D: data.data, MostrarModal: false });
        }
      });
  }
  obtenerCategoriasTreeView() {
    fetch(Globales.Url + 'Modelos/Categoria/ObtenerTreeViewCategoriaModelos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
      //console.log("TCL: AgregarCategoriaModelo -> obtenerCategoriasTreeView -> data", data)
          this.setState({ categoriasModelosTreeView: data });
        
      });
  }



  handleChangeEnabled(event) {
    this.setState({ selectedEnabled: event.target.value });
    if (event.target.value == 'a') {
      this.setState({
        idCategoriaPadre: null
      });
    }
  }
  handleChangeTreeView(idCategoria,target){
    this.setState({
      idCategoriaPadre:idCategoria
    }, () =>{
      this.props.RegresarIdCategoria(idCategoria)
    })
  }
  DataTreeView (treeItems){
    return (
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {this.getTreeItemsFromData(treeItems)}
      </TreeView>
    );
  };
   getTreeItemsFromData(treeItems){
    return treeItems.map(treeItemData => {
      let children = undefined;
      if (treeItemData.CategoriaHijo && treeItemData.CategoriaHijo.length > 0) {
        children =this.getTreeItemsFromData(treeItemData.CategoriaHijo);
      }
      

      return (
        <StyledTreeItem
          key={treeItemData.idCategoria}
          nodeId={treeItemData.idCategoria}
          labelText={treeItemData.NombreCategoria}
          children={children}
          onClick={this.handleChangeTreeView.bind(this,treeItemData.idCategoria)}
          color="#f44336"
        //   bgColor="#f44336"
          labelIcon={this.state.idCategoriaPadre ==treeItemData.idCategoria && children == undefined ? Done:"undefined" }
        />
      );
    });
  }
  render() {  
    const { classes } = this.props;
    return (
      <div>
    
          
         
              {
                this.DataTreeView(this.state.categoriasModelosTreeView)
              }

         
        

      </div>
    );
  }
}