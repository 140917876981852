import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Table from "components/Table/Table.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DoneEstatusCompra from "assets/img/done_estatus_compra.png";
import IconoPendientePago from "assets/img/pendiente_pago.png";
//import IconoCompraCancelada from "assets/img/compra_cancelacion.png";
import IconoCompraCancelada from "assets/img/compra_cancelacion1.png";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import {
  Save,
  Clear,
  ArrowBack,
  MinimizeTwoTone,
  CheckCircleOutline,
  PauseCircleOutline,
  PauseCircleFilled,
  Cached,
  Error,
} from "@material-ui/icons";
import Snackbar from "components/Snackbar/Snackbar.jsx";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";

import Cookies from "js-cookie";
import Globales from "utils/Globales";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import moment from "moment-timezone";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import RepeatIcon from "@material-ui/icons/Repeat";
import HotelIcon from "@material-ui/icons/Hotel";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import { red } from "@material-ui/core/colors";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ModalDetalleVenta extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      EsVenta: null,
      Tallas: [],
      Domicilio: "",
      NumeroSeguimiento: "",
      Contacto: "",
      ReferenciaContacto: "",
      TipoPago: "",
      FechaDeCompra: "",
      /**DATOS PAGO TARJETA*/
      Banco: "",
      Red: "",
      Tipo: "",
      Ultimos4: "",
      Total: "",

      /**DATOS PAGO OXXO*/
      Referencia: "",

      /**Prodcutos */
      Productos: [],
      steps: [],
      numeroStep : 0,
      /**Envio*/
      Paqueteria: "",
      PrecioEnvio: "",
      NumeroGuia: "",
      NumeroDeRastreo: "",
      esCompraEnviada : false,
      pendientePago : false,
      esCompraCancelada: false,
      FolioPedido: "",
      FechaCancelacionPago : "",
    };
  }

  componentDidMount() {
    //console.log(this.props);
    this.setState({
      EsVenta: this.props.EsVenta,
    });
    var DatosPago = this.props.DetallesCompra.responseFromConekta.charges._json
      .data[0];
    if (DatosPago.payment_method.object == "card_payment") {
        this.setState({
          Banco: DatosPago.payment_method.issuer,
          Red: (DatosPago.payment_method.brand != undefined)?DatosPago.payment_method.brand.toUpperCase():"",
          Tipo:
            DatosPago.payment_method.type == "debit"
              ? "DÉBITO"
              : DatosPago.payment_method.type == "credit"
              ? "CRÉDITO"
              : DatosPago.payment_method.type,
          Ultimos4: DatosPago.payment_method.last4,
          Aurotizacion: DatosPago.payment_method.auth_code,
        });
    }

    if (DatosPago.payment_method.object == "cash_payment") {
      this.setState({
        Referencia: DatosPago.payment_method.reference,
      });
    }
    var Domicilio = this.props.DetallesCompra.Domicilio;
    var nProductos = this.props.DetallesCompra.Productos.length;
    fetch(Globales.Url + "Tallas/ObtenerTallas", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
        } else {
          this.setState(
            {
              Tallas: data.data,
            },
            () => {
              for (
                let index = 0;
                index < this.props.DetallesCompra.Productos.length;
                index++
              ) {
                this.props.DetallesCompra.DatosCompra[index].name = this.props.DetallesCompra.Productos[index].Diseno.nombre;
                this.props.DetallesCompra.DatosCompra[index]["talla"] = this.ObtenerNombreTamaño(this.props.DetallesCompra.Productos[index].Tamano);
                this.props.DetallesCompra.DatosCompra[index]["NombreProducto"] = this.props.DetallesCompra.Productos[index].NombreProducto;
                this.props.DetallesCompra.DatosCompra[index]["ImporteComision"] = this.props.DetallesCompra.Productos[index].ImporteComision;
                //this.props.DetallesCompra.DatosCompra[index]["quantity"] = this.props.DetallesCompra.Productos[index].Cantidad;
              }
              this.setState({
                Productos: this.props.DetallesCompra.DatosCompra,
                NumeroSeguimiento: this.props.DetallesCompra.NumeroDeGuia,
              });
            }
          );
        }
      });

    // this.props.DetallesCompra.Productos.map(m =>{
    //   this.props.DetallesCompra.DatosCompra.map(c=>{
    //   c.name=m.Diseno.nombre

    //   })
    // })

    if (this.props.DetallesCompra.listaEstatusPedido.length > 0) {
      this.props.DetallesCompra.listaEstatusPedido.forEach((estatus) => {
        /*         let nombre = estatus.NombreEstatus;
        let consecutivo= estatus.NumeroConsecutivo; */
        var estatusNuevo = {
          nombre: estatus.NombreEstatus,
          numero: estatus.NumeroConsecutivo,
          icono: "",
          imagen : estatus.Icono,
        };
        this.state.steps.push(estatusNuevo);
      });
    }
    this.state.steps.sort((a, b) => a.numero - b.numero);
    let estatusPedido = this.props.DetallesCompra.EstatusPedido;
    let folioPedido = this.props.DetallesCompra.FolioPedido;
    let fechaCancelacionPago = (this.props.DetallesCompra.FechaExpiracionPago == null || this.props.DetallesCompra.FechaExpiracionPago == undefined)?"":this.props.DetallesCompra.FechaExpiracionPago;
    let concluidoSistema = this.props.DetallesCompra.ConcluidoSistema;
    let numRastreoSkyDropbx = this.props.DetallesCompra.responseFromSkyDropx == undefined? "": this.props.DetallesCompra.responseFromSkyDropx.data.attributes.tracking_number;
    let isCompraEnviada = (concluidoSistema == false && numRastreoSkyDropbx == "")?false:true;
    let isPendientePago = (estatusPedido == 0 || estatusPedido == "" || estatusPedido == undefined)?true:false;
    let esCompraCancelada = (this.props.DetallesCompra.Estatus == 2 && isPendientePago == true)?true:false;
    this.setState({
      esCompraEnviada : isCompraEnviada,
      pendientePago: isPendientePago,
      FolioPedido: folioPedido,
      esCompraCancelada: esCompraCancelada,
      FechaCancelacionPago: fechaCancelacionPago,
    });
    const indexNumero = this.state.steps.findIndex(item => item.numero === estatusPedido);
    this.state.steps.forEach(element => {
        if(element.numero == estatusPedido){
          element.icono = <div style={{
            height: "48px",
            width: "48px",
            marginTop: "-10px",
            zIndex: "10000",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#FFD800",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
          <img
            src={element.imagen}
            alt= ""
            style={{ width: "24px", height: "24px" }}
            />
          </div>
        }else{
          element.icono = <div style={{
            height: "48px",
            width: "48px",
            marginTop: "-10px",
            zIndex: "1000",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
          <img
            src={element.imagen}
            alt= ""
            style={{ width: "24px", height: "24px" }}
            />
          </div>
        }
    });
    this.state.numeroStep = indexNumero;
    //console.log("Estos son los datos de la paqueteria a consultar")
    //console.log(this.props.DetallesCompra)
    //console.log("------------")
    this.setState({
      Domicilio:
        Domicilio.Calle +
        ", " +
        Domicilio.Colonia +
        ", " +
        Domicilio.Municipio +
        ", " +
        Domicilio.Estado +
        ", CP:" +
        Domicilio.CodigoPostal,
      Contacto: Domicilio.Nombre + ", " + Domicilio.TelefonoReferencia,
      ReferenciaContacto: (Domicilio.Referencia == undefined || Domicilio.Referencia ==  null)?"":Domicilio.Referencia,
      TipoPago: DatosPago.payment_method.object,
      FechaDeCompra: this.props.DetallesCompra.FechaDeCompra,
      /**Datos pago */
      Total:
        "$" +
        parseFloat(DatosPago.amount / 100, 10)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
          .toString(),

      /**¨Productos */

      /**Envio */
      Paqueteria:
        this.props.DetallesCompra.DatosPaqueteria == undefined
          ? ""
          : ((this.props.DetallesCompra.DatosPaqueteria.data != undefined)?this.props.DetallesCompra.DatosPaqueteria.data.Paqueteria:""),

      PrecioEnvio: (this.props.DetallesCompra.responseFromConekta.shipping_lines
        ._json != undefined)?this.props.DetallesCompra.responseFromConekta.shipping_lines
        ._json.data[0].amount:"",
      NumeroGuia: this.props.DetallesCompra.NumeroDeGuia,
      // NumeroDeRastreo: this.props.DetallesCompra.NumeroDeRastreo,
      NumeroDeRastreo:
        this.props.DetallesCompra.responseFromSkyDropx == undefined
          ? ""
          : ((this.props.DetallesCompra.responseFromSkyDropx.data != undefined)?this.props.DetallesCompra.responseFromSkyDropx.data.attributes
          .tracking_number:""),
      LinkRastreo:
        this.props.DetallesCompra.responseFromSkyDropx == undefined
          ? ""
          :((this.props.DetallesCompra.responseFromSkyDropx.data != undefined)?this.props.DetallesCompra.responseFromSkyDropx.data.attributes
          .tracking_url_provider:""),
    });
  }
  componentWillUnmount() {}

  ObtenerTallas() {}

  CambiarFormatoFecha(Fecha) {
    var FechaMoment = moment(Fecha);
    FechaMoment.tz("America/Mazatlan").format("ha z");

    var Fecha = new Date(FechaMoment._i);
    var Fecha2 = Fecha.toLocaleDateString("en-GB");
    return Fecha2;
  }

  ObtenerNombreTamaño(id) {
    var nombre = "";
    this.state.Tallas.map((m) => {
      if (m._id == id) {
        nombre = m.NombreTalla;
      }
    });
    return nombre;
  }


  render() {
    const { classes } = this.props;

    return (
      <div>
        <GridContainer justify="center" style={{ marginBottom: "3em" }}>
          <GridItem lg={10} md={10} sm={12} xs={12}>
            <span
              onClick={() => this.props.eventoCerrarDetalles(false)}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                fontSize: "1.5em",
              }}
            >
              {" "}
              <ArrowBack /> Regresar a compras{" "}
            </span>
          </GridItem>
        </GridContainer>

        {this.state.EsVenta == false ? (
          <GridContainer justify="center">
            <GridItem lg={4} md={3} sm={12} xs={12}>
              <Card>
                <CardHeader color="primary">Envío</CardHeader>
                <CardBody>
                  <GridItem>
                    <span>
                      {this.state.Paqueteria}{" "}
                      {"$" +
                        parseFloat(this.state.PrecioEnvio / 100, 10)
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                          .toString()}{" "}
                    </span>
                  </GridItem>

                  <GridItem>
                    {/* <span></span> */}
                    {/* <span>Entregado: 12/11/19 </span> */}
                    {/* <span>Entrega estimada: 12/11/19 </span> */}
                  </GridItem>

                  <GridItem>
                    <span>
                      N° seguimiento:{" "}
                      <a style={{ textDecoration: "underline" }}>
                        {" "}
                        {this.state.NumeroSeguimiento == ""
                          ? "No disponible"
                          : this.state.NumeroSeguimiento}{" "}
                      </a>{" "}
                    </span>
                  </GridItem>

                  <GridItem />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem lg={6} md={6} sm={12} xs={12}>
              <Card>
                <CardHeader color="primary">Pago</CardHeader>
                <CardBody>
                  {this.state.TipoPago == "card_payment" ? (
                    <GridItem>
                      {this.state.Red} {this.state.Tipo} {this.state.Banco}{" "}
                      terminada en {this.state.Ultimos4}
                      <br />
                      Pago {this.state.Total}
                      <br />
                      Pago #{this.state.Aurotizacion} acreditado{" "}
                      {this.CambiarFormatoFecha(this.state.FechaDeCompra)}
                    </GridItem>
                  ) : (
                    <GridItem>
                      Referencia OXXO: {this.state.Referencia} <br />
                      Pago: {this.state.Total}
                      <br />
                      Acreditado:{" "}
                      {this.CambiarFormatoFecha(this.state.FechaDeCompra)}
                    </GridItem>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        ) : null}
        <GridContainer justify="center" spacing={2}>
          <GridItem lg={10} md={10} sm={12} xs={12}>
          <Card style={{ width: "100%" }}>
            <CardHeader color="primary" style={{ fontSize: "15px" }}>ESTATUS DE PEDIDO: {this.state.FolioPedido}</CardHeader>
            {this.state.esCompraCancelada ? (
              <CardBody style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={IconoCompraCancelada}
                    alt="Compra cancelada"
                    style={{ 
                      width: '32px', 
                      height: '32px',
                      marginRight: '10px',
                    }} 
                  />
                  <div style={{ fontWeight: 'bold'}}>SU PEDIDO HA SIDO CANCELADO POR FALTA DE PAGO</div>
                </div>
              </CardBody>
            ) : this.state.pendientePago ? (
              <CardBody style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={IconoPendientePago}
                      alt="Pendiente pago"
                      style={{ 
                        width: '32px', 
                        height: '32px',
                        marginRight: '10px',
                      }} 
                    />
                    <div style={{ fontWeight: 'bold'}}>PENDIENTE DE PAGO</div>
                  </div>
                  {this.state.FechaCancelacionPago && (
                    <p style={{ textAlign: 'center' }}>
                      Efectúa el pago antes del {
                        new Date(this.state.FechaCancelacionPago).toLocaleDateString('es-ES', { 
                          weekday: 'long', 
                          month: 'long', 
                          day: 'numeric' 
                        }) + ' a las ' + 
                        new Date(this.state.FechaCancelacionPago).toLocaleTimeString('es-ES', { 
                          hour: 'numeric', 
                          minute: 'numeric',
                          hour12: true 
                        })
                      }
                    </p>  
                  )}
                </div>
              </CardBody>

            ) : this.state.esCompraEnviada ? (
              <CardBody style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={DoneEstatusCompra}
                    alt="Done estatus"
                    style={{ 
                      width: '32px', 
                      height: '32px',
                      marginRight: '10px',
                    }} 
                  />
                  <div style={{ fontWeight: 'bold' }}>SU PEDIDO HA SIDO ENVIADO</div>
                </div>
              </CardBody>
            ) : (
              <CardBody
                style={{
                  overflowX: "auto",
                  height: "150px",
                  marginTop: "10px",
                }}
              >
                <Stepper activeStep={this.state.numeroStep} alternativeLabel>
                  {this.state.steps.map((label, index) => (
                    <Step key={label} style={{ height: "48px", width: "48px" }}>
                      <StepLabel icon={label.icono}>
                        {label.nombre}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </CardBody>
            )}
          </Card>

          </GridItem>
        </GridContainer>

        <GridContainer justify="center" spacing={2}>
          <GridItem lg={10} md={10} sm={12} xs={12}>
            <Card>
              <CardHeader color="primary" style={{fontSize:"15px"}}>INFORMACIÓN DE ENVÍO</CardHeader>
              <CardBody style={{ padding: "10px" }}>
                <GridContainer style = {{padding:"10px"}}>
                  <GridItem>
                    <GridContainer>
                      <GridItem lg={3} md={3} sm={12} xs={12} style={{marginRight:"-15px"}}>
                        <span>Dirección de entrega:&nbsp;</span>
                      </GridItem>
                      <GridItem lg={8} md={8} sm={12} xs={12}>
                        <div style={{marginRight:"50px",}}>
                          <p style={{marginBottom:"15px"}}>{this.state.Domicilio},</p>
                          <p style={{marginBottom:"15px"}}>Referencia:&nbsp; {this.state.ReferenciaContacto}</p>
                          <p style={{marginBottom:"15px"}}>Recibe: &nbsp;{this.state.Contacto}</p>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem style={{marginBottom:"15px"}}>
                    <GridContainer>
                      <GridItem lg={3} md={3} sm={12} xs={12} style={{marginRight:"-15px"}}>
                        <span>Paquetería:</span>
                      </GridItem>
                      <GridItem lg={8} md={8} sm={12} xs={12}>
                        <span>{this.state.Paqueteria}</span>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  {this.state.NumeroDeRastreo && ( 
                  <GridItem>
                    <GridContainer>
                      <GridItem lg={3} md={3} sm={12} xs={12} style={{marginRight:"-15px"}}>
                        <span>Número de Rastreo:</span>
                      </GridItem>
                      <GridItem lg={8} md={8} sm={12} xs={12}>
                        <a href={this.state.LinkRastreo} target="_blank">
                        {this.state.NumeroDeRastreo}
                        </a>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  )}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {/*<GridContainer justify="center">
          <GridItem lg={10} md={10} sm={12} xs={12} style={{paddingRight:'3px',paddingLeft:'3px'}}>
            <Card>
              <CardHeader color="primary" style={{fontSize:"15px"}}>PRODUCTOS</CardHeader>
              <CardBody style = {{marginTop:"-10px"}}>
                <Table style={{fontSize:"12px"}}
                  tableHead={[
                    "Producto",
                    "Talla",
                    "Cantidad",
                    "Precio",
                    "Total",
                    this.state.EsVenta == true ? "comisión" : "",
                  ].map((header, index) => (
                    <th 
                      key={index} 
                      style={{ 
                        fontSize: "13px", 
                        textTransform: "capitalize",
                        display: "block", 
                        textAlign: header === "Producto" || header === "Cantidad"?"center":"left", 
                        marginTop:"-10px",
                        marginBottom:"-10px",
                        fontWeight: "900", 
                        fontStyle: "normal"
                      }}>
                      <span><b style={{fontWeight:"bolder"}}>
                        {header.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())}
                      </b></span>
                    </th>
                    ))}
                  tableData={[
                    ...this.state.Productos.map((producto) => [
                    <div>
                      <span style={{ fontSize: "15px", fontWeight:"900"}}>{producto.name.toUpperCase()}</span>
                      <br />
                      <span style={{ fontSize: "12px", color: "gray" }}>
                        {" "}
                        {producto.NombreProducto.toUpperCase()}
                      </span>
                    </div>,
                    <span style={{ fontSize: "15px", fontWeight:"400"}}>{producto.talla}</span>,
                    <div style={{height:"100%",textAlign:"center"}}>
                      <span style={{ fontSize: "15px", fontWeight:"400"}}>{producto.quantity}</span>
                    </div>,
                    <span style={{ fontSize: "15px", fontWeight:"400"}}>
                      {" "}
                      {"$" +
                        parseFloat(producto.unit_price / 100, 10)
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                          .toString()}
                    </span>,
                    <span style={{ fontSize: "15px", fontWeight:"400"}}>
                      {"$" +
                        parseFloat(
                          (producto.unit_price * producto.quantity) / 100,
                          10
                        )
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                          .toString()}
                    </span>,
                    this.state.EsVenta == true ? (
                      <span>
                        {"$" +
                          parseFloat(producto.ImporteComision, 10)
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                            .toString()}
                      </span>
                    ) : null,
                  ]),
                  [
                    "",
                    "",
                    <div style={{height:"100%",textAlign:"center"}}><b>{this.state.Productos.reduce(
                      (acc, curr) => acc + curr.quantity,
                      0
                    )}</b></div>,
                    "",
                    <b>${(
                      this.state.Productos.reduce(
                        (acc, curr) => acc + curr.unit_price * curr.quantity,
                        0
                      ) / 100
                    ).toFixed(2)}</b>,
                    this.state.EsVenta
                      ? `$${this.state.Productos.reduce(
                          (acc, curr) =>
                            acc +
                            (parseFloat(curr.ImporteComision) || 0),
                          0
                        ).toFixed(2)}`
                      : "",
                  ],
                  ]}
                  tableShopping
                  customHeadCellClasses={[
                    classes.textCenter,
                    classes.description,
                    classes.description,
                    classes.textRight,
                    classes.textRight,
                    classes.textRight,
                  ]}
                  customCellClasses={[
                    classes.tdName,
                    classes.customFont,
                    classes.customFont,
                    classes.tdNumber,
                    classes.tdNumber + " " + classes.tdNumberAndButtonGroup,
                    classes.tdNumber + " " + classes.textCenter,
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
                </GridContainer>*/}

        <GridContainer justify="center">
          <GridItem lg={10} md={10} sm={12} xs={12} style={{paddingRight:'3px',paddingLeft:'3px'}}>
            <Card>
              <CardHeader color="primary" style={{fontSize:"15px"}}>PRODUCTOS</CardHeader>
              <CardBody style = {{marginTop:"-10px"}}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <colgroup>
                    <col style={{ width: "50%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "15%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "15%" }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th style={{ borderBottom: "3px solid rgba(128, 128, 128, 0.3)", textAlign: "center" }}>
                        <b>Producto</b>
                      </th>
                      <th style={{ borderBottom: "3px solid rgba(128, 128, 128, 0.3)",textAlign: "center" }}><b>Talla</b></th>
                      <th style={{ borderBottom: "3px solid rgba(128, 128, 128, 0.3)",textAlign: "center" }}><b>Cantidad</b></th>
                      <th style={{ borderBottom: "3px solid rgba(128, 128, 128, 0.3)",textAlign: "center" }}><b style={{marginLeft:"-30px"}}>Precio</b></th>
                      <th style={{ borderBottom: "3px solid rgba(128, 128, 128, 0.3)",textAlign: "center" }}><b style={{marginLeft:"-30px"}}>Total</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.Productos.map((producto, index) => (
                      <tr key={index} style={{ height: "50px" }}>
                        <td>
                          <div>
                            <div style={{marginBottom:"5px"}}><span style={{ fontSize: "15px"}}><b>{producto.name.toUpperCase()}</b></span></div>
                            <div><span style={{ fontSize: "12px", color: "gray" }}>
                              {producto.NombreProducto.toUpperCase()}
                            </span></div>
                          </div>
                        </td>
                        <td style={{textAlign:"center" }}>
                          <span style={{ fontSize: "15px"}}>{producto.talla}</span>
                        </td>
                        <td style={{textAlign: "center" }}>
                          <span style={{ fontSize: "15px" }}>{producto.quantity}</span>
                        </td>
                        <td style={{ textAlign: "center" }}>
{/*                           <span style={{ fontSize: "15px",float: "left" }}>
                            {"$" + " "}
                          </span> */}
                          <span style={{ fontSize: "15px", float: "right" }}>
                            {"$ " + (parseFloat(producto.unit_price / 100, 10).toFixed(2)).replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                          </span>
                        </td>

                        <td style={{ textAlign: "center" }}>
   {/*                        <span style={{ fontSize: "15px",float: "left", marginLeft:"10px" }}>
                            {"$" + " "}
                          </span> */}
                          <span style={{ fontSize: "15px", float: "right" }}>
                            {"$ " +  (parseFloat((producto.unit_price * producto.quantity) / 100, 10).toFixed(2)).replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                          </span>
                        </td>



                        {this.state.EsVenta && (
                          <td style={{textAlign: "right" }}>
                            <span style={{ fontSize: "15px"}}>
                              {"$ " + (parseFloat(producto.ImporteComision) || 0).toFixed(2)}
                            </span>
                          </td>
                        )}
                      </tr>
                    ))}
                    <tr style={{ borderTop: "3px solid rgba(128, 128, 128, 0.3)",marginTop:"10px" }}>
                      <td colSpan={2}> 
                      </td>
                      <td style={{textAlign: "center" }}>
                        <div style={{marginTop:"5px"}}><b style={{marginTop:"10px"}}>{this.state.Productos.reduce((acc, curr) => acc + curr.quantity, 0)}</b></div>
                      </td>
                      <td style={{ textAlign: "right" }} colSpan={2}>
                        <div style={{marginTop:"5px"}}><b style={{marginTop:"10px"}}>
                          {"$" + " " + (this.state.Productos.reduce(
                            (acc, curr) => acc + curr.unit_price * curr.quantity, 0) / 100
                          ).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                        </b></div>
                      </td>

                      {this.state.EsVenta && (
                        <td style={{ textAlign: "right" }}>
                          <div style={{marginTop:"5px"}}><b style={{marginTop:"10px"}}>
                            {"$ " + (this.state.Productos.reduce(
                              (acc, curr) => acc + (parseFloat(curr.ImporteComision) || 0),
                              0
                            ).toFixed(2)).replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                          </b></div>
                        </td>

                      )}
                    </tr>
                  </tbody>
              </table>
              </CardBody>
              </Card>
            </GridItem>
        </GridContainer>
        
      </div>
    );
  }
}

export default withStyles(modalStyle)(ModalDetalleVenta);
